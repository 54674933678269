import React from "react"

import { ArtistType } from "../../types/ArtistType"
import { buildSlug } from "../../helpers/Helpers"
import ListItem from "../Elements/ListItem"
import QSNavLink from "../Elements/QSNavLink"
import RoundedImage from "../Elements/RoundedImage"

interface ArtistListItemProps {
  artist: ArtistType
}

const ArtistListItem = ({ artist }: ArtistListItemProps): JSX.Element => {
  return (
    <ListItem
      imgUrl={artist.Files[0]?.File?.url}
      title={artist.name}
      shareTitle={`Check out the artist “${artist.name}” on Rungh Redux`}
      compact={!artist.inScope}
      idSlug={buildSlug(artist)}
      urlSlug={buildSlug(artist, { url: true })}
      hideFrieze
    >
      <div className="border-t border-slate-800 py-0 italic my-3 text-sm w-full"></div>
      {artist.PersonContributions.map((pc) => (
        <div key={pc.Contribution.id} className="my-3">
          <QSNavLink
            path={`/${buildSlug(pc.Contribution, { url: true })}`}
            className="flex items-center"
          >
            <RoundedImage
              size="small"
              url={pc.Contribution.Issue?.Files?.[0].File?.url}
              className="mr-2"
            />
            <div className="leading-tight">
              <div className="text-sm sm:text-base leading-tight sm:leading-tight mb-1">
                {pc.Contribution.title}
              </div>
              <div className="text-xxs sm:text-xs text-slate-600 uppercase font-semibold">
                Volume {pc.Contribution.Issue.volume} • Issue{" "}
                {pc.Contribution.Issue.issueNumber} •{" "}
                {pc.Contribution.Issue.year}
              </div>
            </div>
          </QSNavLink>
        </div>
      ))}
      {artist.runghLink ? (
        <div className="text-right mt-4 text-sm italic">
          <a
            className={`border bg-rr-khaki-darkest hover:bg-rr-khaki-dark border-rr-khaki-darkest text-white hover:text-slate-800 rounded py-1 px-2`}
            href={artist.runghLink}
            target="_blank"
            rel="noreferrer"
          >
            View bio on Rungh &rarr;
          </a>
        </div>
      ) : (
        <></>
      )}
    </ListItem>
  )
}

export default ArtistListItem
