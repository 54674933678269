import React from "react"
import { Route, Switch } from "react-router-dom"
import AllLists from "../AllLists/AllLists"

const Router = (): JSX.Element => {
  return (
    <Switch>
      <Route exact path={`/:type?/:id?`} component={AllLists} />
      <Route exact path={`/`} component={AllLists} />
    </Switch>
  )
}

export default Router
