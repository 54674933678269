import { NavLink } from "react-router-dom"
import { useLocation } from "react-router"
import React from "react"

interface Props {
  activeClassName?: string
  children: React.ReactChild | React.ReactChild[]
  className?: string
  path: string
}

const QSNavLink = (props: Props): JSX.Element => {
  const location = useLocation()

  return (
    <NavLink
      to={{ pathname: props.path, search: location.search }}
      {...props}
      onClick={(e) => e.stopPropagation()}
      className={`hover:underline ${props.className}`}
    >
      {props.children}
    </NavLink>
  )
}

export default QSNavLink
