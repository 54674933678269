import React from "react"
import QSNavLink from "./QSNavLink"

interface HeaderLinkProps {
  linkId: string
  title: string
  arrow: "left" | "right"
}

const HeaderLink = ({ arrow, linkId, title }: HeaderLinkProps): JSX.Element => {
  return (
    <QSNavLink path={`/${linkId}`} className={"text-xs sm:text-base px-2"}>
      {arrow === "left" ? <span>&larr;&nbsp;</span> : <></>}
      {title}
      {arrow === "right" ? <span>&nbsp;&rarr;</span> : <></>}
    </QSNavLink>
  )
}

export default HeaderLink
