import React, { useState } from "react"
import Modal from "../Modal/Modal"

import runghReduxLogo from "./redux_logo_white.png"

const Nav = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(true)

  return (
    <>
      <Modal open={modalOpen} setOpen={setModalOpen} />
      <div className="h-[calc(2.5rem)] sm:h-[calc(4rem)] bg-gray-900 text-white fixed w-full top-0 mb-[calc(4rem)] flex items-center p-2 sm:p-3 justify-between">
        <p className="w-1/3 text-left">
          <button
            className="rounded border border-gray-500 py-1 px-2 hover:bg-gray-700 italic sm:text-sm text-xs h-6 sm:h-fit inline-block"
            onClick={() => setModalOpen(true)}
          >
            About <span className="hidden sm:inline">Rungh Redux</span>
          </button>
        </p>
        <div className="w-1/3 text-center">
          <img
            src={runghReduxLogo}
            alt="Rungh Redux"
            className="h-6 sm:h-10 mx-auto"
          />
        </div>
        <p className="w-1/3 text-right">
          <a
            href="https://rungh.org"
            className="rounded border border-gray-500 py-1 px-2 hover:bg-gray-700 italic sm:text-sm text-xs h-6 sm:h-fit inline-block"
            target="_blank"
            rel="noreferrer"
          >
            Visit Rungh<span className="hidden sm:inline">.org</span> &rarr;
          </a>
        </p>
        {/* <h1 className="text-center font-bold text-2xl my-auto">Rungh Redux</h1> */}
        {/* <button
          className="rounded border border-white px-2 py-1 hover:bg-gray-800"
          onClick={toggleSlideover}
        >
          Explore
        </button> */}
      </div>
    </>
  )
}

export default Nav
