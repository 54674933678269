import { ArrayParam, useQueryParam } from "use-query-params"
import React, { useCallback, useEffect, useState } from "react"

import { addTag, hasTag, removeTag } from "./TagList"

interface Props {
  tagName: string
}

// const BORDER_COLOR_MAP: Record<string, string> = {
//   Conversation:
//     "border-rr-forest-dark hover:bg-rr-forest-base hover:text-white",
//   Dance: "border-rr-chocolate-dark hover:bg-rr-chocolate-base hover:text-white",
//   Fiction: "border-rr-ochre-dark hover:bg-rr-ochre-base hover:text-white",
//   Film: "border-rr-navy-dark hover:bg-rr-navy-base hover:text-white",
//   Literature:
//     "border-rr-cerulean-dark hover:bg-rr-cerulean-base hover:text-white",
//   Music: "border-rr-lemon-dark hover:bg-rr-lemon-base hover:text-white",
//   Poetry: "border-rr-plum-dark hover:bg-rr-plum-base hover:text-white",
//   Reflection:
//     "border-rr-lavender-dark hover:bg-rr-lavender-base hover:text-white",
//   Review: "border-rr-cashmere-dark hover:bg-rr-cashmere-base hover:text-white",
//   Archive: "border-rr-caramel-dark hover:bg-rr-caramel-base hover:text-white",
//   "Artist Run Centre":
//     "border-rr-orange-dark hover:bg-rr-orange-base hover:text-white",
//   Body: "border-rr-cherry-dark hover:bg-rr-cherry-base hover:text-white",
//   "Desh Pradesh":
//     "border-rr-grass-dark hover:bg-rr-grass-base hover:text-white",
//   Queer: "border-rr-chocolate-dark hover:bg-rr-chocolate-base hover:text-white",
//   Roots: "border-rr-ochre-dark hover:bg-rr-ochre-base hover:text-white",
// }

// const ACTIVE_COLOR_MAP: Record<string, string> = {
//   Conversation:
//     "border-rr-forest-dark bg-rr-forest-base text-white hover:text-black hover:bg-rr-forest-light",
//   Dance:
//     "border-rr-chocolate-dark bg-rr-chocolate-base text-white hover:text-black hover:bg-rr-chocolate-light",
//   Fiction:
//     "border-rr-ochre-dark bg-rr-ochre-base text-white hover:text-black hover:bg-rr-ochre-light",
//   Film: "border-rr-navy-dark bg-rr-navy-base text-white hover:text-black hover:bg-rr-navy-light",
//   Literature:
//     "border-rr-cerulean-dark bg-rr-cerulean-base text-white hover:text-black hover:bg-rr-cerulean-light",
//   Music:
//     "border-rr-lemon-dark bg-rr-lemon-base text-white hover:text-black hover:bg-rr-lemon-light",
//   Poetry:
//     "border-rr-plum-dark bg-rr-plum-base text-white hover:text-black hover:bg-rr-plum-light",
//   Reflection:
//     "border-rr-lavender-dark bg-rr-lavender-base text-white hover:text-black hover:bg-rr-lavender-light",
//   Review:
//     "border-rr-cashmere-dark bg-rr-cashmere-base text-white hover:text-black hover:bg-rr-cashmere-light",
//   Archive:
//     "border-rr-caramel-dark bg-rr-caramel-base text-white hover:text-black hover:bg-rr-caramel-light",
//   "Artist Run Centre":
//     "border-rr-orange-dark bg-rr-orange-base text-white hover:text-black hover:bg-rr-orange-light",
//   Body: "border-rr-cherry-dark bg-rr-cherry-base text-white hover:text-black hover:bg-rr-cherry-light",
//   "Desh Pradesh":
//     "border-rr-grass-dark bg-rr-grass-base text-white hover:text-black hover:bg-rr-grass-light",
//   Queer:
//     "border-rr-chocolate-dark bg-rr-chocolate-base text-white hover:text-black hover:bg-rr-chocolate-light",
//   Roots:
//     "border-rr-ochre-dark bg-rr-ochre-base text-white hover:text-black hover:bg-rr-ochre-light",
// }

const TagButton = ({ tagName }: Props): JSX.Element => {
  const [isActive, setIsActive] = useState<boolean>(false)
  const [tags, setTags] = useQueryParam("tags", ArrayParam)

  const tagLength = tags?.length

  const toggleTag = useCallback(
    (tagName: string) => {
      const newTags = tags ? [...tags] : []

      if (hasTag(newTags, tagName)) {
        removeTag(newTags, tagName)
      } else {
        addTag(newTags, tagName)
      }

      setTags([...newTags].filter((t) => t !== undefined))
    },
    [tags, setTags]
  )

  useEffect(() => {
    setIsActive(hasTag(tags, tagName))
  }, [tags, tagLength, tagName])

  return (
    <button
      className={`py-0.5 sm:py-1 px-1 sm:px-2 m-0.5 sm:m-1 border-2 rounded border-rr-chocolate-dark text-xs sm:text-sm ${
        isActive ? "bg-rr-chocolate-dark text-white" : ""
      } ${tagName.replaceAll(" ", "-")} `}
      onClick={() => toggleTag(tagName)}
    >
      {tagName}
    </button>
  )
}

export default TagButton
