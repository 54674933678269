import React from "react"

import { buildSlug } from "../../helpers/Helpers"
import { IssueType } from "../../types/IssueType"
import ListItem, { ISSUE_COLOR_MAP } from "../Elements/ListItem"
import QSNavLink from "../Elements/QSNavLink"

interface IssueListItemProps {
  issue: IssueType
}

const IssueListItem = ({ issue }: IssueListItemProps): JSX.Element => {
  const { darkBg, bg: lightBg, border } = ISSUE_COLOR_MAP[issue.id]

  return (
    <ListItem
      imgUrl={issue.Files[0]?.File?.url}
      compact={!issue.inScope}
      title={
        <>
          {issue.title}{" "}
          <span className="text-sm text-rr-khaki-darkest ml-1">
            {issue.year}
          </span>
        </>
      }
      shareTitle={`Check out the issue “${issue.title}” on Rungh Redux`}
      idSlug={buildSlug(issue)}
      urlSlug={buildSlug(issue, { url: true })}
      issueId={issue.id}
      isExtraLong
    >
      <div className="text-xxs sm:text-xs text-slate-600 uppercase font-semibold mt-0 sm:mt-2">
        Volume {issue.volume} • Issue {issue.issueNumber}
      </div>
      <div className="border-t border-slate-800 py-0 italic my-3 text-sm w-full"></div>
      <div className="space-y-3">
        {issue.Contributions.map((c) => (
          <QSNavLink
            key={c.id}
            path={`/${buildSlug(c, { url: true })}`}
            className="flex items-center"
          >
            <div className="leading-tight sm:leading-5">
              <div className="mb-1 text-sm sm:text-base">{c.title}</div>
              <div className="text-xxs sm:text-xs text-slate-600 uppercase font-semibold">
                {c.PersonContributions.map((pc, idx) => (
                  <span className="no-underline" key={pc.Person.id}>
                    {idx > 0 && <span> • </span>}
                    <span>{pc.Person.name}</span>
                  </span>
                ))}
              </div>
            </div>
          </QSNavLink>
        ))}
      </div>
      {issue.runghLink ? (
        <div className="text-right mb-2 mt-4 text-xs sm:text-sm italic">
          <a
            className={`border hover:${lightBg} ${darkBg} ${border} text-white hover:text-slate-800 rounded py-0 sm:py-1 px-1 sm:px-2`}
            href={issue.runghLink}
            target={"_blank"}
            rel="noreferrer"
          >
            More on Rungh &rarr;
          </a>
        </div>
      ) : (
        <></>
      )}
    </ListItem>
  )
}

export default IssueListItem
