import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  date: any;
  timestamptz: any;
  uuid: any;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** columns and relationships of "Contribution" */
export type Contribution = {
  __typename?: 'Contribution';
  /** An array relationship */
  Comments: Array<EntityComment>;
  /** An aggregated array relationship */
  Comments_aggregate: EntityComment_Aggregate;
  /** An array relationship */
  Files: Array<EntityFile>;
  /** An aggregated array relationship */
  Files_aggregate: EntityFile_Aggregate;
  /** An object relationship */
  Issue: Issue;
  /** An array relationship */
  PersonContributions: Array<PersonContribution>;
  /** An aggregated array relationship */
  PersonContributions_aggregate: PersonContribution_Aggregate;
  archiveLink?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  contributionType?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  eventDate?: Maybe<Scalars['date']>;
  fullText?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isApproved: Scalars['Boolean'];
  issueId: Scalars['Int'];
  link?: Maybe<Scalars['String']>;
  runghLink?: Maybe<Scalars['String']>;
  snippet?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  uuid: Scalars['uuid'];
};


/** columns and relationships of "Contribution" */
export type ContributionCommentsArgs = {
  distinct_on?: InputMaybe<Array<EntityComment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntityComment_Order_By>>;
  where?: InputMaybe<EntityComment_Bool_Exp>;
};


/** columns and relationships of "Contribution" */
export type ContributionComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EntityComment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntityComment_Order_By>>;
  where?: InputMaybe<EntityComment_Bool_Exp>;
};


/** columns and relationships of "Contribution" */
export type ContributionFilesArgs = {
  distinct_on?: InputMaybe<Array<EntityFile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntityFile_Order_By>>;
  where?: InputMaybe<EntityFile_Bool_Exp>;
};


/** columns and relationships of "Contribution" */
export type ContributionFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EntityFile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntityFile_Order_By>>;
  where?: InputMaybe<EntityFile_Bool_Exp>;
};


/** columns and relationships of "Contribution" */
export type ContributionPersonContributionsArgs = {
  distinct_on?: InputMaybe<Array<PersonContribution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PersonContribution_Order_By>>;
  where?: InputMaybe<PersonContribution_Bool_Exp>;
};


/** columns and relationships of "Contribution" */
export type ContributionPersonContributions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PersonContribution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PersonContribution_Order_By>>;
  where?: InputMaybe<PersonContribution_Bool_Exp>;
};

/** aggregated selection of "Contribution" */
export type Contribution_Aggregate = {
  __typename?: 'Contribution_aggregate';
  aggregate?: Maybe<Contribution_Aggregate_Fields>;
  nodes: Array<Contribution>;
};

/** aggregate fields of "Contribution" */
export type Contribution_Aggregate_Fields = {
  __typename?: 'Contribution_aggregate_fields';
  avg?: Maybe<Contribution_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Contribution_Max_Fields>;
  min?: Maybe<Contribution_Min_Fields>;
  stddev?: Maybe<Contribution_Stddev_Fields>;
  stddev_pop?: Maybe<Contribution_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contribution_Stddev_Samp_Fields>;
  sum?: Maybe<Contribution_Sum_Fields>;
  var_pop?: Maybe<Contribution_Var_Pop_Fields>;
  var_samp?: Maybe<Contribution_Var_Samp_Fields>;
  variance?: Maybe<Contribution_Variance_Fields>;
};


/** aggregate fields of "Contribution" */
export type Contribution_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contribution_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Contribution" */
export type Contribution_Aggregate_Order_By = {
  avg?: InputMaybe<Contribution_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contribution_Max_Order_By>;
  min?: InputMaybe<Contribution_Min_Order_By>;
  stddev?: InputMaybe<Contribution_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Contribution_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Contribution_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Contribution_Sum_Order_By>;
  var_pop?: InputMaybe<Contribution_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Contribution_Var_Samp_Order_By>;
  variance?: InputMaybe<Contribution_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Contribution" */
export type Contribution_Arr_Rel_Insert_Input = {
  data: Array<Contribution_Insert_Input>;
  on_conflict?: InputMaybe<Contribution_On_Conflict>;
};

/** aggregate avg on columns */
export type Contribution_Avg_Fields = {
  __typename?: 'Contribution_avg_fields';
  id?: Maybe<Scalars['Float']>;
  issueId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Contribution" */
export type Contribution_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  issueId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Contribution". All fields are combined with a logical 'AND'. */
export type Contribution_Bool_Exp = {
  Comments?: InputMaybe<EntityComment_Bool_Exp>;
  Files?: InputMaybe<EntityFile_Bool_Exp>;
  Issue?: InputMaybe<Issue_Bool_Exp>;
  PersonContributions?: InputMaybe<PersonContribution_Bool_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Contribution_Bool_Exp>>>;
  _not?: InputMaybe<Contribution_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Contribution_Bool_Exp>>>;
  archiveLink?: InputMaybe<String_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  contributionType?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  eventDate?: InputMaybe<Date_Comparison_Exp>;
  fullText?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isApproved?: InputMaybe<Boolean_Comparison_Exp>;
  issueId?: InputMaybe<Int_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  runghLink?: InputMaybe<String_Comparison_Exp>;
  snippet?: InputMaybe<String_Comparison_Exp>;
  subtitle?: InputMaybe<String_Comparison_Exp>;
  tags?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Contribution" */
export enum Contribution_Constraint {
  /** unique or primary key constraint */
  ContributionPkey = 'Contribution_pkey',
  /** unique or primary key constraint */
  ContributionUuidKey = 'Contribution_uuid_key'
}

/** input type for incrementing integer column in table "Contribution" */
export type Contribution_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  issueId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Contribution" */
export type Contribution_Insert_Input = {
  Comments?: InputMaybe<EntityComment_Arr_Rel_Insert_Input>;
  Files?: InputMaybe<EntityFile_Arr_Rel_Insert_Input>;
  Issue?: InputMaybe<Issue_Obj_Rel_Insert_Input>;
  PersonContributions?: InputMaybe<PersonContribution_Arr_Rel_Insert_Input>;
  archiveLink?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  contributionType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  eventDate?: InputMaybe<Scalars['date']>;
  fullText?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  issueId?: InputMaybe<Scalars['Int']>;
  link?: InputMaybe<Scalars['String']>;
  runghLink?: InputMaybe<Scalars['String']>;
  snippet?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  uuid?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Contribution_Max_Fields = {
  __typename?: 'Contribution_max_fields';
  archiveLink?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  contributionType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventDate?: Maybe<Scalars['date']>;
  fullText?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  issueId?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  runghLink?: Maybe<Scalars['String']>;
  snippet?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "Contribution" */
export type Contribution_Max_Order_By = {
  archiveLink?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  contributionType?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  eventDate?: InputMaybe<Order_By>;
  fullText?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  issueId?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  runghLink?: InputMaybe<Order_By>;
  snippet?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contribution_Min_Fields = {
  __typename?: 'Contribution_min_fields';
  archiveLink?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  contributionType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  eventDate?: Maybe<Scalars['date']>;
  fullText?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  issueId?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  runghLink?: Maybe<Scalars['String']>;
  snippet?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "Contribution" */
export type Contribution_Min_Order_By = {
  archiveLink?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  contributionType?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  eventDate?: InputMaybe<Order_By>;
  fullText?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  issueId?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  runghLink?: InputMaybe<Order_By>;
  snippet?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Contribution" */
export type Contribution_Mutation_Response = {
  __typename?: 'Contribution_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Contribution>;
};

/** input type for inserting object relation for remote table "Contribution" */
export type Contribution_Obj_Rel_Insert_Input = {
  data: Contribution_Insert_Input;
  on_conflict?: InputMaybe<Contribution_On_Conflict>;
};

/** on conflict condition type for table "Contribution" */
export type Contribution_On_Conflict = {
  constraint: Contribution_Constraint;
  update_columns: Array<Contribution_Update_Column>;
  where?: InputMaybe<Contribution_Bool_Exp>;
};

/** ordering options when selecting data from "Contribution" */
export type Contribution_Order_By = {
  Comments_aggregate?: InputMaybe<EntityComment_Aggregate_Order_By>;
  Files_aggregate?: InputMaybe<EntityFile_Aggregate_Order_By>;
  Issue?: InputMaybe<Issue_Order_By>;
  PersonContributions_aggregate?: InputMaybe<PersonContribution_Aggregate_Order_By>;
  archiveLink?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  contributionType?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  eventDate?: InputMaybe<Order_By>;
  fullText?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isApproved?: InputMaybe<Order_By>;
  issueId?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  runghLink?: InputMaybe<Order_By>;
  snippet?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "Contribution" */
export type Contribution_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Contribution" */
export enum Contribution_Select_Column {
  /** column name */
  ArchiveLink = 'archiveLink',
  /** column name */
  Comment = 'comment',
  /** column name */
  ContributionType = 'contributionType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventDate = 'eventDate',
  /** column name */
  FullText = 'fullText',
  /** column name */
  Id = 'id',
  /** column name */
  IsApproved = 'isApproved',
  /** column name */
  IssueId = 'issueId',
  /** column name */
  Link = 'link',
  /** column name */
  RunghLink = 'runghLink',
  /** column name */
  Snippet = 'snippet',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Tags = 'tags',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Uuid = 'uuid'
}

/** input type for updating data in table "Contribution" */
export type Contribution_Set_Input = {
  archiveLink?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  contributionType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  eventDate?: InputMaybe<Scalars['date']>;
  fullText?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  issueId?: InputMaybe<Scalars['Int']>;
  link?: InputMaybe<Scalars['String']>;
  runghLink?: InputMaybe<Scalars['String']>;
  snippet?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  uuid?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Contribution_Stddev_Fields = {
  __typename?: 'Contribution_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  issueId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Contribution" */
export type Contribution_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  issueId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Contribution_Stddev_Pop_Fields = {
  __typename?: 'Contribution_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  issueId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Contribution" */
export type Contribution_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  issueId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Contribution_Stddev_Samp_Fields = {
  __typename?: 'Contribution_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  issueId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Contribution" */
export type Contribution_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  issueId?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Contribution_Sum_Fields = {
  __typename?: 'Contribution_sum_fields';
  id?: Maybe<Scalars['Int']>;
  issueId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Contribution" */
export type Contribution_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  issueId?: InputMaybe<Order_By>;
};

/** update columns of table "Contribution" */
export enum Contribution_Update_Column {
  /** column name */
  ArchiveLink = 'archiveLink',
  /** column name */
  Comment = 'comment',
  /** column name */
  ContributionType = 'contributionType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventDate = 'eventDate',
  /** column name */
  FullText = 'fullText',
  /** column name */
  Id = 'id',
  /** column name */
  IsApproved = 'isApproved',
  /** column name */
  IssueId = 'issueId',
  /** column name */
  Link = 'link',
  /** column name */
  RunghLink = 'runghLink',
  /** column name */
  Snippet = 'snippet',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Tags = 'tags',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Uuid = 'uuid'
}

/** aggregate var_pop on columns */
export type Contribution_Var_Pop_Fields = {
  __typename?: 'Contribution_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  issueId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Contribution" */
export type Contribution_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  issueId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Contribution_Var_Samp_Fields = {
  __typename?: 'Contribution_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  issueId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Contribution" */
export type Contribution_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  issueId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Contribution_Variance_Fields = {
  __typename?: 'Contribution_variance_fields';
  id?: Maybe<Scalars['Float']>;
  issueId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Contribution" */
export type Contribution_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  issueId?: InputMaybe<Order_By>;
};

/** columns and relationships of "EntityComment" */
export type EntityComment = {
  __typename?: 'EntityComment';
  /** An array relationship */
  ContributionComments: Array<Contribution>;
  /** An aggregated array relationship */
  ContributionComments_aggregate: Contribution_Aggregate;
  /** An array relationship */
  PersonComments: Array<Person>;
  /** An aggregated array relationship */
  PersonComments_aggregate: Person_Aggregate;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  email?: Maybe<Scalars['String']>;
  entityType: Scalars['String'];
  entityUuid: Scalars['uuid'];
  hasBeenEdited: Scalars['Boolean'];
  id: Scalars['Int'];
  inviteKey?: Maybe<Scalars['String']>;
  isApproved: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "EntityComment" */
export type EntityCommentContributionCommentsArgs = {
  distinct_on?: InputMaybe<Array<Contribution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contribution_Order_By>>;
  where?: InputMaybe<Contribution_Bool_Exp>;
};


/** columns and relationships of "EntityComment" */
export type EntityCommentContributionComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contribution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contribution_Order_By>>;
  where?: InputMaybe<Contribution_Bool_Exp>;
};


/** columns and relationships of "EntityComment" */
export type EntityCommentPersonCommentsArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


/** columns and relationships of "EntityComment" */
export type EntityCommentPersonComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};

/** aggregated selection of "EntityComment" */
export type EntityComment_Aggregate = {
  __typename?: 'EntityComment_aggregate';
  aggregate?: Maybe<EntityComment_Aggregate_Fields>;
  nodes: Array<EntityComment>;
};

/** aggregate fields of "EntityComment" */
export type EntityComment_Aggregate_Fields = {
  __typename?: 'EntityComment_aggregate_fields';
  avg?: Maybe<EntityComment_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<EntityComment_Max_Fields>;
  min?: Maybe<EntityComment_Min_Fields>;
  stddev?: Maybe<EntityComment_Stddev_Fields>;
  stddev_pop?: Maybe<EntityComment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<EntityComment_Stddev_Samp_Fields>;
  sum?: Maybe<EntityComment_Sum_Fields>;
  var_pop?: Maybe<EntityComment_Var_Pop_Fields>;
  var_samp?: Maybe<EntityComment_Var_Samp_Fields>;
  variance?: Maybe<EntityComment_Variance_Fields>;
};


/** aggregate fields of "EntityComment" */
export type EntityComment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EntityComment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "EntityComment" */
export type EntityComment_Aggregate_Order_By = {
  avg?: InputMaybe<EntityComment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<EntityComment_Max_Order_By>;
  min?: InputMaybe<EntityComment_Min_Order_By>;
  stddev?: InputMaybe<EntityComment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<EntityComment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<EntityComment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<EntityComment_Sum_Order_By>;
  var_pop?: InputMaybe<EntityComment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<EntityComment_Var_Samp_Order_By>;
  variance?: InputMaybe<EntityComment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "EntityComment" */
export type EntityComment_Arr_Rel_Insert_Input = {
  data: Array<EntityComment_Insert_Input>;
  on_conflict?: InputMaybe<EntityComment_On_Conflict>;
};

/** aggregate avg on columns */
export type EntityComment_Avg_Fields = {
  __typename?: 'EntityComment_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "EntityComment" */
export type EntityComment_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "EntityComment". All fields are combined with a logical 'AND'. */
export type EntityComment_Bool_Exp = {
  ContributionComments?: InputMaybe<Contribution_Bool_Exp>;
  PersonComments?: InputMaybe<Person_Bool_Exp>;
  _and?: InputMaybe<Array<InputMaybe<EntityComment_Bool_Exp>>>;
  _not?: InputMaybe<EntityComment_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<EntityComment_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  entityType?: InputMaybe<String_Comparison_Exp>;
  entityUuid?: InputMaybe<Uuid_Comparison_Exp>;
  hasBeenEdited?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  inviteKey?: InputMaybe<String_Comparison_Exp>;
  isApproved?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "EntityComment" */
export enum EntityComment_Constraint {
  /** unique or primary key constraint */
  CommentPkey = 'Comment_pkey'
}

/** input type for incrementing integer column in table "EntityComment" */
export type EntityComment_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "EntityComment" */
export type EntityComment_Insert_Input = {
  ContributionComments?: InputMaybe<Contribution_Arr_Rel_Insert_Input>;
  PersonComments?: InputMaybe<Person_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<Scalars['String']>;
  entityUuid?: InputMaybe<Scalars['uuid']>;
  hasBeenEdited?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  inviteKey?: InputMaybe<Scalars['String']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EntityComment_Max_Fields = {
  __typename?: 'EntityComment_max_fields';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  entityUuid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  inviteKey?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "EntityComment" */
export type EntityComment_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  entityType?: InputMaybe<Order_By>;
  entityUuid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inviteKey?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type EntityComment_Min_Fields = {
  __typename?: 'EntityComment_min_fields';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  entityUuid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  inviteKey?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "EntityComment" */
export type EntityComment_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  entityType?: InputMaybe<Order_By>;
  entityUuid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inviteKey?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "EntityComment" */
export type EntityComment_Mutation_Response = {
  __typename?: 'EntityComment_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<EntityComment>;
};

/** input type for inserting object relation for remote table "EntityComment" */
export type EntityComment_Obj_Rel_Insert_Input = {
  data: EntityComment_Insert_Input;
  on_conflict?: InputMaybe<EntityComment_On_Conflict>;
};

/** on conflict condition type for table "EntityComment" */
export type EntityComment_On_Conflict = {
  constraint: EntityComment_Constraint;
  update_columns: Array<EntityComment_Update_Column>;
  where?: InputMaybe<EntityComment_Bool_Exp>;
};

/** ordering options when selecting data from "EntityComment" */
export type EntityComment_Order_By = {
  ContributionComments_aggregate?: InputMaybe<Contribution_Aggregate_Order_By>;
  PersonComments_aggregate?: InputMaybe<Person_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  entityType?: InputMaybe<Order_By>;
  entityUuid?: InputMaybe<Order_By>;
  hasBeenEdited?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inviteKey?: InputMaybe<Order_By>;
  isApproved?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "EntityComment" */
export type EntityComment_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "EntityComment" */
export enum EntityComment_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  EntityType = 'entityType',
  /** column name */
  EntityUuid = 'entityUuid',
  /** column name */
  HasBeenEdited = 'hasBeenEdited',
  /** column name */
  Id = 'id',
  /** column name */
  InviteKey = 'inviteKey',
  /** column name */
  IsApproved = 'isApproved',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "EntityComment" */
export type EntityComment_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<Scalars['String']>;
  entityUuid?: InputMaybe<Scalars['uuid']>;
  hasBeenEdited?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  inviteKey?: InputMaybe<Scalars['String']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EntityComment_Stddev_Fields = {
  __typename?: 'EntityComment_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "EntityComment" */
export type EntityComment_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type EntityComment_Stddev_Pop_Fields = {
  __typename?: 'EntityComment_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "EntityComment" */
export type EntityComment_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type EntityComment_Stddev_Samp_Fields = {
  __typename?: 'EntityComment_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "EntityComment" */
export type EntityComment_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type EntityComment_Sum_Fields = {
  __typename?: 'EntityComment_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "EntityComment" */
export type EntityComment_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "EntityComment" */
export enum EntityComment_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  EntityType = 'entityType',
  /** column name */
  EntityUuid = 'entityUuid',
  /** column name */
  HasBeenEdited = 'hasBeenEdited',
  /** column name */
  Id = 'id',
  /** column name */
  InviteKey = 'inviteKey',
  /** column name */
  IsApproved = 'isApproved',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type EntityComment_Var_Pop_Fields = {
  __typename?: 'EntityComment_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "EntityComment" */
export type EntityComment_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type EntityComment_Var_Samp_Fields = {
  __typename?: 'EntityComment_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "EntityComment" */
export type EntityComment_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type EntityComment_Variance_Fields = {
  __typename?: 'EntityComment_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "EntityComment" */
export type EntityComment_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "EntityFile" */
export type EntityFile = {
  __typename?: 'EntityFile';
  /** An array relationship */
  ContributionFiles: Array<Contribution>;
  /** An aggregated array relationship */
  ContributionFiles_aggregate: Contribution_Aggregate;
  /** An object relationship */
  File?: Maybe<File>;
  /** An array relationship */
  IssueFiles: Array<Issue>;
  /** An aggregated array relationship */
  IssueFiles_aggregate: Issue_Aggregate;
  /** An array relationship */
  PersonFiles: Array<Person>;
  /** An aggregated array relationship */
  PersonFiles_aggregate: Person_Aggregate;
  createdAt: Scalars['timestamptz'];
  entityType: Scalars['String'];
  entityUuid: Scalars['uuid'];
  fileId: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "EntityFile" */
export type EntityFileContributionFilesArgs = {
  distinct_on?: InputMaybe<Array<Contribution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contribution_Order_By>>;
  where?: InputMaybe<Contribution_Bool_Exp>;
};


/** columns and relationships of "EntityFile" */
export type EntityFileContributionFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contribution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contribution_Order_By>>;
  where?: InputMaybe<Contribution_Bool_Exp>;
};


/** columns and relationships of "EntityFile" */
export type EntityFileIssueFilesArgs = {
  distinct_on?: InputMaybe<Array<Issue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Issue_Order_By>>;
  where?: InputMaybe<Issue_Bool_Exp>;
};


/** columns and relationships of "EntityFile" */
export type EntityFileIssueFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Issue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Issue_Order_By>>;
  where?: InputMaybe<Issue_Bool_Exp>;
};


/** columns and relationships of "EntityFile" */
export type EntityFilePersonFilesArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


/** columns and relationships of "EntityFile" */
export type EntityFilePersonFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};

/** aggregated selection of "EntityFile" */
export type EntityFile_Aggregate = {
  __typename?: 'EntityFile_aggregate';
  aggregate?: Maybe<EntityFile_Aggregate_Fields>;
  nodes: Array<EntityFile>;
};

/** aggregate fields of "EntityFile" */
export type EntityFile_Aggregate_Fields = {
  __typename?: 'EntityFile_aggregate_fields';
  avg?: Maybe<EntityFile_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<EntityFile_Max_Fields>;
  min?: Maybe<EntityFile_Min_Fields>;
  stddev?: Maybe<EntityFile_Stddev_Fields>;
  stddev_pop?: Maybe<EntityFile_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<EntityFile_Stddev_Samp_Fields>;
  sum?: Maybe<EntityFile_Sum_Fields>;
  var_pop?: Maybe<EntityFile_Var_Pop_Fields>;
  var_samp?: Maybe<EntityFile_Var_Samp_Fields>;
  variance?: Maybe<EntityFile_Variance_Fields>;
};


/** aggregate fields of "EntityFile" */
export type EntityFile_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EntityFile_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "EntityFile" */
export type EntityFile_Aggregate_Order_By = {
  avg?: InputMaybe<EntityFile_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<EntityFile_Max_Order_By>;
  min?: InputMaybe<EntityFile_Min_Order_By>;
  stddev?: InputMaybe<EntityFile_Stddev_Order_By>;
  stddev_pop?: InputMaybe<EntityFile_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<EntityFile_Stddev_Samp_Order_By>;
  sum?: InputMaybe<EntityFile_Sum_Order_By>;
  var_pop?: InputMaybe<EntityFile_Var_Pop_Order_By>;
  var_samp?: InputMaybe<EntityFile_Var_Samp_Order_By>;
  variance?: InputMaybe<EntityFile_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "EntityFile" */
export type EntityFile_Arr_Rel_Insert_Input = {
  data: Array<EntityFile_Insert_Input>;
  on_conflict?: InputMaybe<EntityFile_On_Conflict>;
};

/** aggregate avg on columns */
export type EntityFile_Avg_Fields = {
  __typename?: 'EntityFile_avg_fields';
  fileId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "EntityFile" */
export type EntityFile_Avg_Order_By = {
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "EntityFile". All fields are combined with a logical 'AND'. */
export type EntityFile_Bool_Exp = {
  ContributionFiles?: InputMaybe<Contribution_Bool_Exp>;
  File?: InputMaybe<File_Bool_Exp>;
  IssueFiles?: InputMaybe<Issue_Bool_Exp>;
  PersonFiles?: InputMaybe<Person_Bool_Exp>;
  _and?: InputMaybe<Array<InputMaybe<EntityFile_Bool_Exp>>>;
  _not?: InputMaybe<EntityFile_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<EntityFile_Bool_Exp>>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  entityType?: InputMaybe<String_Comparison_Exp>;
  entityUuid?: InputMaybe<Uuid_Comparison_Exp>;
  fileId?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "EntityFile" */
export enum EntityFile_Constraint {
  /** unique or primary key constraint */
  EntityFilePkey = 'EntityFile_pkey'
}

/** input type for incrementing integer column in table "EntityFile" */
export type EntityFile_Inc_Input = {
  fileId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "EntityFile" */
export type EntityFile_Insert_Input = {
  ContributionFiles?: InputMaybe<Contribution_Arr_Rel_Insert_Input>;
  File?: InputMaybe<File_Obj_Rel_Insert_Input>;
  IssueFiles?: InputMaybe<Issue_Arr_Rel_Insert_Input>;
  PersonFiles?: InputMaybe<Person_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  entityType?: InputMaybe<Scalars['String']>;
  entityUuid?: InputMaybe<Scalars['uuid']>;
  fileId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EntityFile_Max_Fields = {
  __typename?: 'EntityFile_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  entityType?: Maybe<Scalars['String']>;
  entityUuid?: Maybe<Scalars['uuid']>;
  fileId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "EntityFile" */
export type EntityFile_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  entityType?: InputMaybe<Order_By>;
  entityUuid?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type EntityFile_Min_Fields = {
  __typename?: 'EntityFile_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  entityType?: Maybe<Scalars['String']>;
  entityUuid?: Maybe<Scalars['uuid']>;
  fileId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "EntityFile" */
export type EntityFile_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  entityType?: InputMaybe<Order_By>;
  entityUuid?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "EntityFile" */
export type EntityFile_Mutation_Response = {
  __typename?: 'EntityFile_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<EntityFile>;
};

/** input type for inserting object relation for remote table "EntityFile" */
export type EntityFile_Obj_Rel_Insert_Input = {
  data: EntityFile_Insert_Input;
  on_conflict?: InputMaybe<EntityFile_On_Conflict>;
};

/** on conflict condition type for table "EntityFile" */
export type EntityFile_On_Conflict = {
  constraint: EntityFile_Constraint;
  update_columns: Array<EntityFile_Update_Column>;
  where?: InputMaybe<EntityFile_Bool_Exp>;
};

/** ordering options when selecting data from "EntityFile" */
export type EntityFile_Order_By = {
  ContributionFiles_aggregate?: InputMaybe<Contribution_Aggregate_Order_By>;
  File?: InputMaybe<File_Order_By>;
  IssueFiles_aggregate?: InputMaybe<Issue_Aggregate_Order_By>;
  PersonFiles_aggregate?: InputMaybe<Person_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  entityType?: InputMaybe<Order_By>;
  entityUuid?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "EntityFile" */
export type EntityFile_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "EntityFile" */
export enum EntityFile_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EntityType = 'entityType',
  /** column name */
  EntityUuid = 'entityUuid',
  /** column name */
  FileId = 'fileId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "EntityFile" */
export type EntityFile_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  entityType?: InputMaybe<Scalars['String']>;
  entityUuid?: InputMaybe<Scalars['uuid']>;
  fileId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EntityFile_Stddev_Fields = {
  __typename?: 'EntityFile_stddev_fields';
  fileId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "EntityFile" */
export type EntityFile_Stddev_Order_By = {
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type EntityFile_Stddev_Pop_Fields = {
  __typename?: 'EntityFile_stddev_pop_fields';
  fileId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "EntityFile" */
export type EntityFile_Stddev_Pop_Order_By = {
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type EntityFile_Stddev_Samp_Fields = {
  __typename?: 'EntityFile_stddev_samp_fields';
  fileId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "EntityFile" */
export type EntityFile_Stddev_Samp_Order_By = {
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type EntityFile_Sum_Fields = {
  __typename?: 'EntityFile_sum_fields';
  fileId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "EntityFile" */
export type EntityFile_Sum_Order_By = {
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "EntityFile" */
export enum EntityFile_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EntityType = 'entityType',
  /** column name */
  EntityUuid = 'entityUuid',
  /** column name */
  FileId = 'fileId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type EntityFile_Var_Pop_Fields = {
  __typename?: 'EntityFile_var_pop_fields';
  fileId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "EntityFile" */
export type EntityFile_Var_Pop_Order_By = {
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type EntityFile_Var_Samp_Fields = {
  __typename?: 'EntityFile_var_samp_fields';
  fileId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "EntityFile" */
export type EntityFile_Var_Samp_Order_By = {
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type EntityFile_Variance_Fields = {
  __typename?: 'EntityFile_variance_fields';
  fileId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "EntityFile" */
export type EntityFile_Variance_Order_By = {
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "File" */
export type File = {
  __typename?: 'File';
  createdAt: Scalars['timestamptz'];
  fileName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  source?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  url: Scalars['String'];
};

/** aggregated selection of "File" */
export type File_Aggregate = {
  __typename?: 'File_aggregate';
  aggregate?: Maybe<File_Aggregate_Fields>;
  nodes: Array<File>;
};

/** aggregate fields of "File" */
export type File_Aggregate_Fields = {
  __typename?: 'File_aggregate_fields';
  avg?: Maybe<File_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<File_Max_Fields>;
  min?: Maybe<File_Min_Fields>;
  stddev?: Maybe<File_Stddev_Fields>;
  stddev_pop?: Maybe<File_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<File_Stddev_Samp_Fields>;
  sum?: Maybe<File_Sum_Fields>;
  var_pop?: Maybe<File_Var_Pop_Fields>;
  var_samp?: Maybe<File_Var_Samp_Fields>;
  variance?: Maybe<File_Variance_Fields>;
};


/** aggregate fields of "File" */
export type File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "File" */
export type File_Aggregate_Order_By = {
  avg?: InputMaybe<File_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<File_Max_Order_By>;
  min?: InputMaybe<File_Min_Order_By>;
  stddev?: InputMaybe<File_Stddev_Order_By>;
  stddev_pop?: InputMaybe<File_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<File_Stddev_Samp_Order_By>;
  sum?: InputMaybe<File_Sum_Order_By>;
  var_pop?: InputMaybe<File_Var_Pop_Order_By>;
  var_samp?: InputMaybe<File_Var_Samp_Order_By>;
  variance?: InputMaybe<File_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "File" */
export type File_Arr_Rel_Insert_Input = {
  data: Array<File_Insert_Input>;
  on_conflict?: InputMaybe<File_On_Conflict>;
};

/** aggregate avg on columns */
export type File_Avg_Fields = {
  __typename?: 'File_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "File" */
export type File_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "File". All fields are combined with a logical 'AND'. */
export type File_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<File_Bool_Exp>>>;
  _not?: InputMaybe<File_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<File_Bool_Exp>>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  fileName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "File" */
export enum File_Constraint {
  /** unique or primary key constraint */
  FilePkey = 'File_pkey'
}

/** input type for incrementing integer column in table "File" */
export type File_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "File" */
export type File_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fileName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type File_Max_Fields = {
  __typename?: 'File_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "File" */
export type File_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  fileName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type File_Min_Fields = {
  __typename?: 'File_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "File" */
export type File_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  fileName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "File" */
export type File_Mutation_Response = {
  __typename?: 'File_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<File>;
};

/** input type for inserting object relation for remote table "File" */
export type File_Obj_Rel_Insert_Input = {
  data: File_Insert_Input;
  on_conflict?: InputMaybe<File_On_Conflict>;
};

/** on conflict condition type for table "File" */
export type File_On_Conflict = {
  constraint: File_Constraint;
  update_columns: Array<File_Update_Column>;
  where?: InputMaybe<File_Bool_Exp>;
};

/** ordering options when selecting data from "File" */
export type File_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  fileName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "File" */
export type File_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "File" */
export enum File_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FileName = 'fileName',
  /** column name */
  Id = 'id',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "File" */
export type File_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fileName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type File_Stddev_Fields = {
  __typename?: 'File_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "File" */
export type File_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type File_Stddev_Pop_Fields = {
  __typename?: 'File_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "File" */
export type File_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type File_Stddev_Samp_Fields = {
  __typename?: 'File_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "File" */
export type File_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type File_Sum_Fields = {
  __typename?: 'File_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "File" */
export type File_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "File" */
export enum File_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FileName = 'fileName',
  /** column name */
  Id = 'id',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

/** aggregate var_pop on columns */
export type File_Var_Pop_Fields = {
  __typename?: 'File_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "File" */
export type File_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type File_Var_Samp_Fields = {
  __typename?: 'File_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "File" */
export type File_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type File_Variance_Fields = {
  __typename?: 'File_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "File" */
export type File_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** columns and relationships of "Issue" */
export type Issue = {
  __typename?: 'Issue';
  /** An array relationship */
  Contributions: Array<Contribution>;
  /** An aggregated array relationship */
  Contributions_aggregate: Contribution_Aggregate;
  /** An array relationship */
  Files: Array<EntityFile>;
  /** An aggregated array relationship */
  Files_aggregate: EntityFile_Aggregate;
  archiveLink?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['Int'];
  isApproved: Scalars['Boolean'];
  issueNumber: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  reflection?: Maybe<Scalars['String']>;
  runghLink?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  uuid: Scalars['uuid'];
  volume: Scalars['String'];
  year: Scalars['String'];
};


/** columns and relationships of "Issue" */
export type IssueContributionsArgs = {
  distinct_on?: InputMaybe<Array<Contribution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contribution_Order_By>>;
  where?: InputMaybe<Contribution_Bool_Exp>;
};


/** columns and relationships of "Issue" */
export type IssueContributions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contribution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contribution_Order_By>>;
  where?: InputMaybe<Contribution_Bool_Exp>;
};


/** columns and relationships of "Issue" */
export type IssueFilesArgs = {
  distinct_on?: InputMaybe<Array<EntityFile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntityFile_Order_By>>;
  where?: InputMaybe<EntityFile_Bool_Exp>;
};


/** columns and relationships of "Issue" */
export type IssueFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EntityFile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntityFile_Order_By>>;
  where?: InputMaybe<EntityFile_Bool_Exp>;
};

/** aggregated selection of "Issue" */
export type Issue_Aggregate = {
  __typename?: 'Issue_aggregate';
  aggregate?: Maybe<Issue_Aggregate_Fields>;
  nodes: Array<Issue>;
};

/** aggregate fields of "Issue" */
export type Issue_Aggregate_Fields = {
  __typename?: 'Issue_aggregate_fields';
  avg?: Maybe<Issue_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Issue_Max_Fields>;
  min?: Maybe<Issue_Min_Fields>;
  stddev?: Maybe<Issue_Stddev_Fields>;
  stddev_pop?: Maybe<Issue_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Issue_Stddev_Samp_Fields>;
  sum?: Maybe<Issue_Sum_Fields>;
  var_pop?: Maybe<Issue_Var_Pop_Fields>;
  var_samp?: Maybe<Issue_Var_Samp_Fields>;
  variance?: Maybe<Issue_Variance_Fields>;
};


/** aggregate fields of "Issue" */
export type Issue_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Issue_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Issue" */
export type Issue_Aggregate_Order_By = {
  avg?: InputMaybe<Issue_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Issue_Max_Order_By>;
  min?: InputMaybe<Issue_Min_Order_By>;
  stddev?: InputMaybe<Issue_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Issue_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Issue_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Issue_Sum_Order_By>;
  var_pop?: InputMaybe<Issue_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Issue_Var_Samp_Order_By>;
  variance?: InputMaybe<Issue_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Issue" */
export type Issue_Arr_Rel_Insert_Input = {
  data: Array<Issue_Insert_Input>;
  on_conflict?: InputMaybe<Issue_On_Conflict>;
};

/** aggregate avg on columns */
export type Issue_Avg_Fields = {
  __typename?: 'Issue_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Issue" */
export type Issue_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Issue". All fields are combined with a logical 'AND'. */
export type Issue_Bool_Exp = {
  Contributions?: InputMaybe<Contribution_Bool_Exp>;
  Files?: InputMaybe<EntityFile_Bool_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Issue_Bool_Exp>>>;
  _not?: InputMaybe<Issue_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Issue_Bool_Exp>>>;
  archiveLink?: InputMaybe<String_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isApproved?: InputMaybe<Boolean_Comparison_Exp>;
  issueNumber?: InputMaybe<String_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  reflection?: InputMaybe<String_Comparison_Exp>;
  runghLink?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  uuid?: InputMaybe<Uuid_Comparison_Exp>;
  volume?: InputMaybe<String_Comparison_Exp>;
  year?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Issue" */
export enum Issue_Constraint {
  /** unique or primary key constraint */
  IssuePkey = 'Issue_pkey',
  /** unique or primary key constraint */
  IssueUuidKey = 'Issue_uuid_key'
}

/** input type for incrementing integer column in table "Issue" */
export type Issue_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Issue" */
export type Issue_Insert_Input = {
  Contributions?: InputMaybe<Contribution_Arr_Rel_Insert_Input>;
  Files?: InputMaybe<EntityFile_Arr_Rel_Insert_Input>;
  archiveLink?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  issueNumber?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  reflection?: InputMaybe<Scalars['String']>;
  runghLink?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  uuid?: InputMaybe<Scalars['uuid']>;
  volume?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Issue_Max_Fields = {
  __typename?: 'Issue_max_fields';
  archiveLink?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  issueNumber?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  reflection?: Maybe<Scalars['String']>;
  runghLink?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
  volume?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Issue" */
export type Issue_Max_Order_By = {
  archiveLink?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  issueNumber?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  reflection?: InputMaybe<Order_By>;
  runghLink?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
  volume?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Issue_Min_Fields = {
  __typename?: 'Issue_min_fields';
  archiveLink?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  issueNumber?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  reflection?: Maybe<Scalars['String']>;
  runghLink?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
  volume?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Issue" */
export type Issue_Min_Order_By = {
  archiveLink?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  issueNumber?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  reflection?: InputMaybe<Order_By>;
  runghLink?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
  volume?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Issue" */
export type Issue_Mutation_Response = {
  __typename?: 'Issue_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Issue>;
};

/** input type for inserting object relation for remote table "Issue" */
export type Issue_Obj_Rel_Insert_Input = {
  data: Issue_Insert_Input;
  on_conflict?: InputMaybe<Issue_On_Conflict>;
};

/** on conflict condition type for table "Issue" */
export type Issue_On_Conflict = {
  constraint: Issue_Constraint;
  update_columns: Array<Issue_Update_Column>;
  where?: InputMaybe<Issue_Bool_Exp>;
};

/** ordering options when selecting data from "Issue" */
export type Issue_Order_By = {
  Contributions_aggregate?: InputMaybe<Contribution_Aggregate_Order_By>;
  Files_aggregate?: InputMaybe<EntityFile_Aggregate_Order_By>;
  archiveLink?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isApproved?: InputMaybe<Order_By>;
  issueNumber?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  reflection?: InputMaybe<Order_By>;
  runghLink?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
  volume?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "Issue" */
export type Issue_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Issue" */
export enum Issue_Select_Column {
  /** column name */
  ArchiveLink = 'archiveLink',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsApproved = 'isApproved',
  /** column name */
  IssueNumber = 'issueNumber',
  /** column name */
  Link = 'link',
  /** column name */
  Reflection = 'reflection',
  /** column name */
  RunghLink = 'runghLink',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  Volume = 'volume',
  /** column name */
  Year = 'year'
}

/** input type for updating data in table "Issue" */
export type Issue_Set_Input = {
  archiveLink?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  issueNumber?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  reflection?: InputMaybe<Scalars['String']>;
  runghLink?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  uuid?: InputMaybe<Scalars['uuid']>;
  volume?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Issue_Stddev_Fields = {
  __typename?: 'Issue_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Issue" */
export type Issue_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Issue_Stddev_Pop_Fields = {
  __typename?: 'Issue_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Issue" */
export type Issue_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Issue_Stddev_Samp_Fields = {
  __typename?: 'Issue_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Issue" */
export type Issue_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Issue_Sum_Fields = {
  __typename?: 'Issue_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Issue" */
export type Issue_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "Issue" */
export enum Issue_Update_Column {
  /** column name */
  ArchiveLink = 'archiveLink',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsApproved = 'isApproved',
  /** column name */
  IssueNumber = 'issueNumber',
  /** column name */
  Link = 'link',
  /** column name */
  Reflection = 'reflection',
  /** column name */
  RunghLink = 'runghLink',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  Volume = 'volume',
  /** column name */
  Year = 'year'
}

/** aggregate var_pop on columns */
export type Issue_Var_Pop_Fields = {
  __typename?: 'Issue_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Issue" */
export type Issue_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Issue_Var_Samp_Fields = {
  __typename?: 'Issue_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Issue" */
export type Issue_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Issue_Variance_Fields = {
  __typename?: 'Issue_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Issue" */
export type Issue_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "Person" */
export type Person = {
  __typename?: 'Person';
  /** An array relationship */
  Comments: Array<EntityComment>;
  /** An aggregated array relationship */
  Comments_aggregate: EntityComment_Aggregate;
  /** An array relationship */
  Files: Array<EntityFile>;
  /** An aggregated array relationship */
  Files_aggregate: EntityFile_Aggregate;
  /** An array relationship */
  PersonContributions: Array<PersonContribution>;
  /** An aggregated array relationship */
  PersonContributions_aggregate: PersonContribution_Aggregate;
  archiveLink?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['Int'];
  isApproved: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  runghLink?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  uuid: Scalars['uuid'];
};


/** columns and relationships of "Person" */
export type PersonCommentsArgs = {
  distinct_on?: InputMaybe<Array<EntityComment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntityComment_Order_By>>;
  where?: InputMaybe<EntityComment_Bool_Exp>;
};


/** columns and relationships of "Person" */
export type PersonComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EntityComment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntityComment_Order_By>>;
  where?: InputMaybe<EntityComment_Bool_Exp>;
};


/** columns and relationships of "Person" */
export type PersonFilesArgs = {
  distinct_on?: InputMaybe<Array<EntityFile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntityFile_Order_By>>;
  where?: InputMaybe<EntityFile_Bool_Exp>;
};


/** columns and relationships of "Person" */
export type PersonFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EntityFile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntityFile_Order_By>>;
  where?: InputMaybe<EntityFile_Bool_Exp>;
};


/** columns and relationships of "Person" */
export type PersonPersonContributionsArgs = {
  distinct_on?: InputMaybe<Array<PersonContribution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PersonContribution_Order_By>>;
  where?: InputMaybe<PersonContribution_Bool_Exp>;
};


/** columns and relationships of "Person" */
export type PersonPersonContributions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PersonContribution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PersonContribution_Order_By>>;
  where?: InputMaybe<PersonContribution_Bool_Exp>;
};

/** columns and relationships of "PersonContribution" */
export type PersonContribution = {
  __typename?: 'PersonContribution';
  /** An object relationship */
  Contribution: Contribution;
  /** An object relationship */
  Person: Person;
  contributionId: Scalars['Int'];
  personId: Scalars['Int'];
};

/** aggregated selection of "PersonContribution" */
export type PersonContribution_Aggregate = {
  __typename?: 'PersonContribution_aggregate';
  aggregate?: Maybe<PersonContribution_Aggregate_Fields>;
  nodes: Array<PersonContribution>;
};

/** aggregate fields of "PersonContribution" */
export type PersonContribution_Aggregate_Fields = {
  __typename?: 'PersonContribution_aggregate_fields';
  avg?: Maybe<PersonContribution_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<PersonContribution_Max_Fields>;
  min?: Maybe<PersonContribution_Min_Fields>;
  stddev?: Maybe<PersonContribution_Stddev_Fields>;
  stddev_pop?: Maybe<PersonContribution_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PersonContribution_Stddev_Samp_Fields>;
  sum?: Maybe<PersonContribution_Sum_Fields>;
  var_pop?: Maybe<PersonContribution_Var_Pop_Fields>;
  var_samp?: Maybe<PersonContribution_Var_Samp_Fields>;
  variance?: Maybe<PersonContribution_Variance_Fields>;
};


/** aggregate fields of "PersonContribution" */
export type PersonContribution_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PersonContribution_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "PersonContribution" */
export type PersonContribution_Aggregate_Order_By = {
  avg?: InputMaybe<PersonContribution_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PersonContribution_Max_Order_By>;
  min?: InputMaybe<PersonContribution_Min_Order_By>;
  stddev?: InputMaybe<PersonContribution_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PersonContribution_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PersonContribution_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PersonContribution_Sum_Order_By>;
  var_pop?: InputMaybe<PersonContribution_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PersonContribution_Var_Samp_Order_By>;
  variance?: InputMaybe<PersonContribution_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "PersonContribution" */
export type PersonContribution_Arr_Rel_Insert_Input = {
  data: Array<PersonContribution_Insert_Input>;
  on_conflict?: InputMaybe<PersonContribution_On_Conflict>;
};

/** aggregate avg on columns */
export type PersonContribution_Avg_Fields = {
  __typename?: 'PersonContribution_avg_fields';
  contributionId?: Maybe<Scalars['Float']>;
  personId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "PersonContribution" */
export type PersonContribution_Avg_Order_By = {
  contributionId?: InputMaybe<Order_By>;
  personId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "PersonContribution". All fields are combined with a logical 'AND'. */
export type PersonContribution_Bool_Exp = {
  Contribution?: InputMaybe<Contribution_Bool_Exp>;
  Person?: InputMaybe<Person_Bool_Exp>;
  _and?: InputMaybe<Array<InputMaybe<PersonContribution_Bool_Exp>>>;
  _not?: InputMaybe<PersonContribution_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<PersonContribution_Bool_Exp>>>;
  contributionId?: InputMaybe<Int_Comparison_Exp>;
  personId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "PersonContribution" */
export enum PersonContribution_Constraint {
  /** unique or primary key constraint */
  PersonContributionPkey = 'PersonContribution_pkey'
}

/** input type for incrementing integer column in table "PersonContribution" */
export type PersonContribution_Inc_Input = {
  contributionId?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "PersonContribution" */
export type PersonContribution_Insert_Input = {
  Contribution?: InputMaybe<Contribution_Obj_Rel_Insert_Input>;
  Person?: InputMaybe<Person_Obj_Rel_Insert_Input>;
  contributionId?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type PersonContribution_Max_Fields = {
  __typename?: 'PersonContribution_max_fields';
  contributionId?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "PersonContribution" */
export type PersonContribution_Max_Order_By = {
  contributionId?: InputMaybe<Order_By>;
  personId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PersonContribution_Min_Fields = {
  __typename?: 'PersonContribution_min_fields';
  contributionId?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "PersonContribution" */
export type PersonContribution_Min_Order_By = {
  contributionId?: InputMaybe<Order_By>;
  personId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "PersonContribution" */
export type PersonContribution_Mutation_Response = {
  __typename?: 'PersonContribution_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<PersonContribution>;
};

/** input type for inserting object relation for remote table "PersonContribution" */
export type PersonContribution_Obj_Rel_Insert_Input = {
  data: PersonContribution_Insert_Input;
  on_conflict?: InputMaybe<PersonContribution_On_Conflict>;
};

/** on conflict condition type for table "PersonContribution" */
export type PersonContribution_On_Conflict = {
  constraint: PersonContribution_Constraint;
  update_columns: Array<PersonContribution_Update_Column>;
  where?: InputMaybe<PersonContribution_Bool_Exp>;
};

/** ordering options when selecting data from "PersonContribution" */
export type PersonContribution_Order_By = {
  Contribution?: InputMaybe<Contribution_Order_By>;
  Person?: InputMaybe<Person_Order_By>;
  contributionId?: InputMaybe<Order_By>;
  personId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "PersonContribution" */
export type PersonContribution_Pk_Columns_Input = {
  contributionId: Scalars['Int'];
  personId: Scalars['Int'];
};

/** select columns of table "PersonContribution" */
export enum PersonContribution_Select_Column {
  /** column name */
  ContributionId = 'contributionId',
  /** column name */
  PersonId = 'personId'
}

/** input type for updating data in table "PersonContribution" */
export type PersonContribution_Set_Input = {
  contributionId?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type PersonContribution_Stddev_Fields = {
  __typename?: 'PersonContribution_stddev_fields';
  contributionId?: Maybe<Scalars['Float']>;
  personId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "PersonContribution" */
export type PersonContribution_Stddev_Order_By = {
  contributionId?: InputMaybe<Order_By>;
  personId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PersonContribution_Stddev_Pop_Fields = {
  __typename?: 'PersonContribution_stddev_pop_fields';
  contributionId?: Maybe<Scalars['Float']>;
  personId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "PersonContribution" */
export type PersonContribution_Stddev_Pop_Order_By = {
  contributionId?: InputMaybe<Order_By>;
  personId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PersonContribution_Stddev_Samp_Fields = {
  __typename?: 'PersonContribution_stddev_samp_fields';
  contributionId?: Maybe<Scalars['Float']>;
  personId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "PersonContribution" */
export type PersonContribution_Stddev_Samp_Order_By = {
  contributionId?: InputMaybe<Order_By>;
  personId?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type PersonContribution_Sum_Fields = {
  __typename?: 'PersonContribution_sum_fields';
  contributionId?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "PersonContribution" */
export type PersonContribution_Sum_Order_By = {
  contributionId?: InputMaybe<Order_By>;
  personId?: InputMaybe<Order_By>;
};

/** update columns of table "PersonContribution" */
export enum PersonContribution_Update_Column {
  /** column name */
  ContributionId = 'contributionId',
  /** column name */
  PersonId = 'personId'
}

/** aggregate var_pop on columns */
export type PersonContribution_Var_Pop_Fields = {
  __typename?: 'PersonContribution_var_pop_fields';
  contributionId?: Maybe<Scalars['Float']>;
  personId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "PersonContribution" */
export type PersonContribution_Var_Pop_Order_By = {
  contributionId?: InputMaybe<Order_By>;
  personId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PersonContribution_Var_Samp_Fields = {
  __typename?: 'PersonContribution_var_samp_fields';
  contributionId?: Maybe<Scalars['Float']>;
  personId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "PersonContribution" */
export type PersonContribution_Var_Samp_Order_By = {
  contributionId?: InputMaybe<Order_By>;
  personId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type PersonContribution_Variance_Fields = {
  __typename?: 'PersonContribution_variance_fields';
  contributionId?: Maybe<Scalars['Float']>;
  personId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "PersonContribution" */
export type PersonContribution_Variance_Order_By = {
  contributionId?: InputMaybe<Order_By>;
  personId?: InputMaybe<Order_By>;
};

/** aggregated selection of "Person" */
export type Person_Aggregate = {
  __typename?: 'Person_aggregate';
  aggregate?: Maybe<Person_Aggregate_Fields>;
  nodes: Array<Person>;
};

/** aggregate fields of "Person" */
export type Person_Aggregate_Fields = {
  __typename?: 'Person_aggregate_fields';
  avg?: Maybe<Person_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Person_Max_Fields>;
  min?: Maybe<Person_Min_Fields>;
  stddev?: Maybe<Person_Stddev_Fields>;
  stddev_pop?: Maybe<Person_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Person_Stddev_Samp_Fields>;
  sum?: Maybe<Person_Sum_Fields>;
  var_pop?: Maybe<Person_Var_Pop_Fields>;
  var_samp?: Maybe<Person_Var_Samp_Fields>;
  variance?: Maybe<Person_Variance_Fields>;
};


/** aggregate fields of "Person" */
export type Person_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Person_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Person" */
export type Person_Aggregate_Order_By = {
  avg?: InputMaybe<Person_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Person_Max_Order_By>;
  min?: InputMaybe<Person_Min_Order_By>;
  stddev?: InputMaybe<Person_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Person_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Person_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Person_Sum_Order_By>;
  var_pop?: InputMaybe<Person_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Person_Var_Samp_Order_By>;
  variance?: InputMaybe<Person_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Person" */
export type Person_Arr_Rel_Insert_Input = {
  data: Array<Person_Insert_Input>;
  on_conflict?: InputMaybe<Person_On_Conflict>;
};

/** aggregate avg on columns */
export type Person_Avg_Fields = {
  __typename?: 'Person_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Person" */
export type Person_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Person". All fields are combined with a logical 'AND'. */
export type Person_Bool_Exp = {
  Comments?: InputMaybe<EntityComment_Bool_Exp>;
  Files?: InputMaybe<EntityFile_Bool_Exp>;
  PersonContributions?: InputMaybe<PersonContribution_Bool_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Person_Bool_Exp>>>;
  _not?: InputMaybe<Person_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Person_Bool_Exp>>>;
  archiveLink?: InputMaybe<String_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isApproved?: InputMaybe<Boolean_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  runghLink?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Person" */
export enum Person_Constraint {
  /** unique or primary key constraint */
  PersonPkey = 'Person_pkey',
  /** unique or primary key constraint */
  PersonUuidKey = 'Person_uuid_key'
}

/** input type for incrementing integer column in table "Person" */
export type Person_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Person" */
export type Person_Insert_Input = {
  Comments?: InputMaybe<EntityComment_Arr_Rel_Insert_Input>;
  Files?: InputMaybe<EntityFile_Arr_Rel_Insert_Input>;
  PersonContributions?: InputMaybe<PersonContribution_Arr_Rel_Insert_Input>;
  archiveLink?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  runghLink?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  uuid?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Person_Max_Fields = {
  __typename?: 'Person_max_fields';
  archiveLink?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  runghLink?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "Person" */
export type Person_Max_Order_By = {
  archiveLink?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  runghLink?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Person_Min_Fields = {
  __typename?: 'Person_min_fields';
  archiveLink?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  runghLink?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "Person" */
export type Person_Min_Order_By = {
  archiveLink?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  runghLink?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Person" */
export type Person_Mutation_Response = {
  __typename?: 'Person_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Person>;
};

/** input type for inserting object relation for remote table "Person" */
export type Person_Obj_Rel_Insert_Input = {
  data: Person_Insert_Input;
  on_conflict?: InputMaybe<Person_On_Conflict>;
};

/** on conflict condition type for table "Person" */
export type Person_On_Conflict = {
  constraint: Person_Constraint;
  update_columns: Array<Person_Update_Column>;
  where?: InputMaybe<Person_Bool_Exp>;
};

/** ordering options when selecting data from "Person" */
export type Person_Order_By = {
  Comments_aggregate?: InputMaybe<EntityComment_Aggregate_Order_By>;
  Files_aggregate?: InputMaybe<EntityFile_Aggregate_Order_By>;
  PersonContributions_aggregate?: InputMaybe<PersonContribution_Aggregate_Order_By>;
  archiveLink?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isApproved?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  runghLink?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "Person" */
export type Person_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Person" */
export enum Person_Select_Column {
  /** column name */
  ArchiveLink = 'archiveLink',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsApproved = 'isApproved',
  /** column name */
  Link = 'link',
  /** column name */
  Name = 'name',
  /** column name */
  RunghLink = 'runghLink',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Uuid = 'uuid'
}

/** input type for updating data in table "Person" */
export type Person_Set_Input = {
  archiveLink?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  runghLink?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  uuid?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Person_Stddev_Fields = {
  __typename?: 'Person_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Person" */
export type Person_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Person_Stddev_Pop_Fields = {
  __typename?: 'Person_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Person" */
export type Person_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Person_Stddev_Samp_Fields = {
  __typename?: 'Person_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Person" */
export type Person_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Person_Sum_Fields = {
  __typename?: 'Person_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Person" */
export type Person_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "Person" */
export enum Person_Update_Column {
  /** column name */
  ArchiveLink = 'archiveLink',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsApproved = 'isApproved',
  /** column name */
  Link = 'link',
  /** column name */
  Name = 'name',
  /** column name */
  RunghLink = 'runghLink',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Uuid = 'uuid'
}

/** aggregate var_pop on columns */
export type Person_Var_Pop_Fields = {
  __typename?: 'Person_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Person" */
export type Person_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Person_Var_Samp_Fields = {
  __typename?: 'Person_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Person" */
export type Person_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Person_Variance_Fields = {
  __typename?: 'Person_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Person" */
export type Person_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  _nlike?: InputMaybe<Scalars['String']>;
  _nsimilar?: InputMaybe<Scalars['String']>;
  _similar?: InputMaybe<Scalars['String']>;
};

/** expression to compare columns of type date. All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "Contribution" */
  delete_Contribution?: Maybe<Contribution_Mutation_Response>;
  /** delete single row from the table: "Contribution" */
  delete_Contribution_by_pk?: Maybe<Contribution>;
  /** delete data from the table: "EntityComment" */
  delete_EntityComment?: Maybe<EntityComment_Mutation_Response>;
  /** delete single row from the table: "EntityComment" */
  delete_EntityComment_by_pk?: Maybe<EntityComment>;
  /** delete data from the table: "EntityFile" */
  delete_EntityFile?: Maybe<EntityFile_Mutation_Response>;
  /** delete single row from the table: "EntityFile" */
  delete_EntityFile_by_pk?: Maybe<EntityFile>;
  /** delete data from the table: "File" */
  delete_File?: Maybe<File_Mutation_Response>;
  /** delete single row from the table: "File" */
  delete_File_by_pk?: Maybe<File>;
  /** delete data from the table: "Issue" */
  delete_Issue?: Maybe<Issue_Mutation_Response>;
  /** delete single row from the table: "Issue" */
  delete_Issue_by_pk?: Maybe<Issue>;
  /** delete data from the table: "Person" */
  delete_Person?: Maybe<Person_Mutation_Response>;
  /** delete data from the table: "PersonContribution" */
  delete_PersonContribution?: Maybe<PersonContribution_Mutation_Response>;
  /** delete single row from the table: "PersonContribution" */
  delete_PersonContribution_by_pk?: Maybe<PersonContribution>;
  /** delete single row from the table: "Person" */
  delete_Person_by_pk?: Maybe<Person>;
  /** insert data into the table: "Contribution" */
  insert_Contribution?: Maybe<Contribution_Mutation_Response>;
  /** insert a single row into the table: "Contribution" */
  insert_Contribution_one?: Maybe<Contribution>;
  /** insert data into the table: "EntityComment" */
  insert_EntityComment?: Maybe<EntityComment_Mutation_Response>;
  /** insert a single row into the table: "EntityComment" */
  insert_EntityComment_one?: Maybe<EntityComment>;
  /** insert data into the table: "EntityFile" */
  insert_EntityFile?: Maybe<EntityFile_Mutation_Response>;
  /** insert a single row into the table: "EntityFile" */
  insert_EntityFile_one?: Maybe<EntityFile>;
  /** insert data into the table: "File" */
  insert_File?: Maybe<File_Mutation_Response>;
  /** insert a single row into the table: "File" */
  insert_File_one?: Maybe<File>;
  /** insert data into the table: "Issue" */
  insert_Issue?: Maybe<Issue_Mutation_Response>;
  /** insert a single row into the table: "Issue" */
  insert_Issue_one?: Maybe<Issue>;
  /** insert data into the table: "Person" */
  insert_Person?: Maybe<Person_Mutation_Response>;
  /** insert data into the table: "PersonContribution" */
  insert_PersonContribution?: Maybe<PersonContribution_Mutation_Response>;
  /** insert a single row into the table: "PersonContribution" */
  insert_PersonContribution_one?: Maybe<PersonContribution>;
  /** insert a single row into the table: "Person" */
  insert_Person_one?: Maybe<Person>;
  /** update data of the table: "Contribution" */
  update_Contribution?: Maybe<Contribution_Mutation_Response>;
  /** update single row of the table: "Contribution" */
  update_Contribution_by_pk?: Maybe<Contribution>;
  /** update data of the table: "EntityComment" */
  update_EntityComment?: Maybe<EntityComment_Mutation_Response>;
  /** update single row of the table: "EntityComment" */
  update_EntityComment_by_pk?: Maybe<EntityComment>;
  /** update data of the table: "EntityFile" */
  update_EntityFile?: Maybe<EntityFile_Mutation_Response>;
  /** update single row of the table: "EntityFile" */
  update_EntityFile_by_pk?: Maybe<EntityFile>;
  /** update data of the table: "File" */
  update_File?: Maybe<File_Mutation_Response>;
  /** update single row of the table: "File" */
  update_File_by_pk?: Maybe<File>;
  /** update data of the table: "Issue" */
  update_Issue?: Maybe<Issue_Mutation_Response>;
  /** update single row of the table: "Issue" */
  update_Issue_by_pk?: Maybe<Issue>;
  /** update data of the table: "Person" */
  update_Person?: Maybe<Person_Mutation_Response>;
  /** update data of the table: "PersonContribution" */
  update_PersonContribution?: Maybe<PersonContribution_Mutation_Response>;
  /** update single row of the table: "PersonContribution" */
  update_PersonContribution_by_pk?: Maybe<PersonContribution>;
  /** update single row of the table: "Person" */
  update_Person_by_pk?: Maybe<Person>;
};


/** mutation root */
export type Mutation_RootDelete_ContributionArgs = {
  where: Contribution_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contribution_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_EntityCommentArgs = {
  where: EntityComment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EntityComment_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_EntityFileArgs = {
  where: EntityFile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EntityFile_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FileArgs = {
  where: File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_File_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_IssueArgs = {
  where: Issue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Issue_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PersonArgs = {
  where: Person_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PersonContributionArgs = {
  where: PersonContribution_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PersonContribution_By_PkArgs = {
  contributionId: Scalars['Int'];
  personId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Person_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_ContributionArgs = {
  objects: Array<Contribution_Insert_Input>;
  on_conflict?: InputMaybe<Contribution_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contribution_OneArgs = {
  object: Contribution_Insert_Input;
  on_conflict?: InputMaybe<Contribution_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EntityCommentArgs = {
  objects: Array<EntityComment_Insert_Input>;
  on_conflict?: InputMaybe<EntityComment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EntityComment_OneArgs = {
  object: EntityComment_Insert_Input;
  on_conflict?: InputMaybe<EntityComment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EntityFileArgs = {
  objects: Array<EntityFile_Insert_Input>;
  on_conflict?: InputMaybe<EntityFile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EntityFile_OneArgs = {
  object: EntityFile_Insert_Input;
  on_conflict?: InputMaybe<EntityFile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FileArgs = {
  objects: Array<File_Insert_Input>;
  on_conflict?: InputMaybe<File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_OneArgs = {
  object: File_Insert_Input;
  on_conflict?: InputMaybe<File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IssueArgs = {
  objects: Array<Issue_Insert_Input>;
  on_conflict?: InputMaybe<Issue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Issue_OneArgs = {
  object: Issue_Insert_Input;
  on_conflict?: InputMaybe<Issue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PersonArgs = {
  objects: Array<Person_Insert_Input>;
  on_conflict?: InputMaybe<Person_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PersonContributionArgs = {
  objects: Array<PersonContribution_Insert_Input>;
  on_conflict?: InputMaybe<PersonContribution_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PersonContribution_OneArgs = {
  object: PersonContribution_Insert_Input;
  on_conflict?: InputMaybe<PersonContribution_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Person_OneArgs = {
  object: Person_Insert_Input;
  on_conflict?: InputMaybe<Person_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_ContributionArgs = {
  _inc?: InputMaybe<Contribution_Inc_Input>;
  _set?: InputMaybe<Contribution_Set_Input>;
  where: Contribution_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contribution_By_PkArgs = {
  _inc?: InputMaybe<Contribution_Inc_Input>;
  _set?: InputMaybe<Contribution_Set_Input>;
  pk_columns: Contribution_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EntityCommentArgs = {
  _inc?: InputMaybe<EntityComment_Inc_Input>;
  _set?: InputMaybe<EntityComment_Set_Input>;
  where: EntityComment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EntityComment_By_PkArgs = {
  _inc?: InputMaybe<EntityComment_Inc_Input>;
  _set?: InputMaybe<EntityComment_Set_Input>;
  pk_columns: EntityComment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EntityFileArgs = {
  _inc?: InputMaybe<EntityFile_Inc_Input>;
  _set?: InputMaybe<EntityFile_Set_Input>;
  where: EntityFile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EntityFile_By_PkArgs = {
  _inc?: InputMaybe<EntityFile_Inc_Input>;
  _set?: InputMaybe<EntityFile_Set_Input>;
  pk_columns: EntityFile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FileArgs = {
  _inc?: InputMaybe<File_Inc_Input>;
  _set?: InputMaybe<File_Set_Input>;
  where: File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_File_By_PkArgs = {
  _inc?: InputMaybe<File_Inc_Input>;
  _set?: InputMaybe<File_Set_Input>;
  pk_columns: File_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_IssueArgs = {
  _inc?: InputMaybe<Issue_Inc_Input>;
  _set?: InputMaybe<Issue_Set_Input>;
  where: Issue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Issue_By_PkArgs = {
  _inc?: InputMaybe<Issue_Inc_Input>;
  _set?: InputMaybe<Issue_Set_Input>;
  pk_columns: Issue_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PersonArgs = {
  _inc?: InputMaybe<Person_Inc_Input>;
  _set?: InputMaybe<Person_Set_Input>;
  where: Person_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PersonContributionArgs = {
  _inc?: InputMaybe<PersonContribution_Inc_Input>;
  _set?: InputMaybe<PersonContribution_Set_Input>;
  where: PersonContribution_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PersonContribution_By_PkArgs = {
  _inc?: InputMaybe<PersonContribution_Inc_Input>;
  _set?: InputMaybe<PersonContribution_Set_Input>;
  pk_columns: PersonContribution_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Person_By_PkArgs = {
  _inc?: InputMaybe<Person_Inc_Input>;
  _set?: InputMaybe<Person_Set_Input>;
  pk_columns: Person_Pk_Columns_Input;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "Contribution" */
  Contribution: Array<Contribution>;
  /** fetch aggregated fields from the table: "Contribution" */
  Contribution_aggregate: Contribution_Aggregate;
  /** fetch data from the table: "Contribution" using primary key columns */
  Contribution_by_pk?: Maybe<Contribution>;
  /** fetch data from the table: "EntityComment" */
  EntityComment: Array<EntityComment>;
  /** fetch aggregated fields from the table: "EntityComment" */
  EntityComment_aggregate: EntityComment_Aggregate;
  /** fetch data from the table: "EntityComment" using primary key columns */
  EntityComment_by_pk?: Maybe<EntityComment>;
  /** fetch data from the table: "EntityFile" */
  EntityFile: Array<EntityFile>;
  /** fetch aggregated fields from the table: "EntityFile" */
  EntityFile_aggregate: EntityFile_Aggregate;
  /** fetch data from the table: "EntityFile" using primary key columns */
  EntityFile_by_pk?: Maybe<EntityFile>;
  /** fetch data from the table: "File" */
  File: Array<File>;
  /** fetch aggregated fields from the table: "File" */
  File_aggregate: File_Aggregate;
  /** fetch data from the table: "File" using primary key columns */
  File_by_pk?: Maybe<File>;
  /** fetch data from the table: "Issue" */
  Issue: Array<Issue>;
  /** fetch aggregated fields from the table: "Issue" */
  Issue_aggregate: Issue_Aggregate;
  /** fetch data from the table: "Issue" using primary key columns */
  Issue_by_pk?: Maybe<Issue>;
  /** fetch data from the table: "Person" */
  Person: Array<Person>;
  /** fetch data from the table: "PersonContribution" */
  PersonContribution: Array<PersonContribution>;
  /** fetch aggregated fields from the table: "PersonContribution" */
  PersonContribution_aggregate: PersonContribution_Aggregate;
  /** fetch data from the table: "PersonContribution" using primary key columns */
  PersonContribution_by_pk?: Maybe<PersonContribution>;
  /** fetch aggregated fields from the table: "Person" */
  Person_aggregate: Person_Aggregate;
  /** fetch data from the table: "Person" using primary key columns */
  Person_by_pk?: Maybe<Person>;
};


/** query root */
export type Query_RootContributionArgs = {
  distinct_on?: InputMaybe<Array<Contribution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contribution_Order_By>>;
  where?: InputMaybe<Contribution_Bool_Exp>;
};


/** query root */
export type Query_RootContribution_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contribution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contribution_Order_By>>;
  where?: InputMaybe<Contribution_Bool_Exp>;
};


/** query root */
export type Query_RootContribution_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootEntityCommentArgs = {
  distinct_on?: InputMaybe<Array<EntityComment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntityComment_Order_By>>;
  where?: InputMaybe<EntityComment_Bool_Exp>;
};


/** query root */
export type Query_RootEntityComment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EntityComment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntityComment_Order_By>>;
  where?: InputMaybe<EntityComment_Bool_Exp>;
};


/** query root */
export type Query_RootEntityComment_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootEntityFileArgs = {
  distinct_on?: InputMaybe<Array<EntityFile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntityFile_Order_By>>;
  where?: InputMaybe<EntityFile_Bool_Exp>;
};


/** query root */
export type Query_RootEntityFile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EntityFile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntityFile_Order_By>>;
  where?: InputMaybe<EntityFile_Bool_Exp>;
};


/** query root */
export type Query_RootEntityFile_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


/** query root */
export type Query_RootFile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


/** query root */
export type Query_RootFile_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootIssueArgs = {
  distinct_on?: InputMaybe<Array<Issue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Issue_Order_By>>;
  where?: InputMaybe<Issue_Bool_Exp>;
};


/** query root */
export type Query_RootIssue_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Issue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Issue_Order_By>>;
  where?: InputMaybe<Issue_Bool_Exp>;
};


/** query root */
export type Query_RootIssue_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootPersonArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


/** query root */
export type Query_RootPersonContributionArgs = {
  distinct_on?: InputMaybe<Array<PersonContribution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PersonContribution_Order_By>>;
  where?: InputMaybe<PersonContribution_Bool_Exp>;
};


/** query root */
export type Query_RootPersonContribution_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PersonContribution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PersonContribution_Order_By>>;
  where?: InputMaybe<PersonContribution_Bool_Exp>;
};


/** query root */
export type Query_RootPersonContribution_By_PkArgs = {
  contributionId: Scalars['Int'];
  personId: Scalars['Int'];
};


/** query root */
export type Query_RootPerson_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


/** query root */
export type Query_RootPerson_By_PkArgs = {
  id: Scalars['Int'];
};

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "Contribution" */
  Contribution: Array<Contribution>;
  /** fetch aggregated fields from the table: "Contribution" */
  Contribution_aggregate: Contribution_Aggregate;
  /** fetch data from the table: "Contribution" using primary key columns */
  Contribution_by_pk?: Maybe<Contribution>;
  /** fetch data from the table: "EntityComment" */
  EntityComment: Array<EntityComment>;
  /** fetch aggregated fields from the table: "EntityComment" */
  EntityComment_aggregate: EntityComment_Aggregate;
  /** fetch data from the table: "EntityComment" using primary key columns */
  EntityComment_by_pk?: Maybe<EntityComment>;
  /** fetch data from the table: "EntityFile" */
  EntityFile: Array<EntityFile>;
  /** fetch aggregated fields from the table: "EntityFile" */
  EntityFile_aggregate: EntityFile_Aggregate;
  /** fetch data from the table: "EntityFile" using primary key columns */
  EntityFile_by_pk?: Maybe<EntityFile>;
  /** fetch data from the table: "File" */
  File: Array<File>;
  /** fetch aggregated fields from the table: "File" */
  File_aggregate: File_Aggregate;
  /** fetch data from the table: "File" using primary key columns */
  File_by_pk?: Maybe<File>;
  /** fetch data from the table: "Issue" */
  Issue: Array<Issue>;
  /** fetch aggregated fields from the table: "Issue" */
  Issue_aggregate: Issue_Aggregate;
  /** fetch data from the table: "Issue" using primary key columns */
  Issue_by_pk?: Maybe<Issue>;
  /** fetch data from the table: "Person" */
  Person: Array<Person>;
  /** fetch data from the table: "PersonContribution" */
  PersonContribution: Array<PersonContribution>;
  /** fetch aggregated fields from the table: "PersonContribution" */
  PersonContribution_aggregate: PersonContribution_Aggregate;
  /** fetch data from the table: "PersonContribution" using primary key columns */
  PersonContribution_by_pk?: Maybe<PersonContribution>;
  /** fetch aggregated fields from the table: "Person" */
  Person_aggregate: Person_Aggregate;
  /** fetch data from the table: "Person" using primary key columns */
  Person_by_pk?: Maybe<Person>;
};


/** subscription root */
export type Subscription_RootContributionArgs = {
  distinct_on?: InputMaybe<Array<Contribution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contribution_Order_By>>;
  where?: InputMaybe<Contribution_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootContribution_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contribution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contribution_Order_By>>;
  where?: InputMaybe<Contribution_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootContribution_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootEntityCommentArgs = {
  distinct_on?: InputMaybe<Array<EntityComment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntityComment_Order_By>>;
  where?: InputMaybe<EntityComment_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEntityComment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EntityComment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntityComment_Order_By>>;
  where?: InputMaybe<EntityComment_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEntityComment_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootEntityFileArgs = {
  distinct_on?: InputMaybe<Array<EntityFile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntityFile_Order_By>>;
  where?: InputMaybe<EntityFile_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEntityFile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EntityFile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntityFile_Order_By>>;
  where?: InputMaybe<EntityFile_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEntityFile_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootFile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootFile_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootIssueArgs = {
  distinct_on?: InputMaybe<Array<Issue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Issue_Order_By>>;
  where?: InputMaybe<Issue_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootIssue_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Issue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Issue_Order_By>>;
  where?: InputMaybe<Issue_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootIssue_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootPersonArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPersonContributionArgs = {
  distinct_on?: InputMaybe<Array<PersonContribution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PersonContribution_Order_By>>;
  where?: InputMaybe<PersonContribution_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPersonContribution_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PersonContribution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PersonContribution_Order_By>>;
  where?: InputMaybe<PersonContribution_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPersonContribution_By_PkArgs = {
  contributionId: Scalars['Int'];
  personId: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootPerson_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPerson_By_PkArgs = {
  id: Scalars['Int'];
};

/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type AllArtistsSlimQueryVariables = Exact<{ [key: string]: never; }>;


export type AllArtistsSlimQuery = { __typename?: 'query_root', Person: Array<{ __typename?: 'Person', id: number, name: string, runghLink?: string | null, PersonContributions: Array<{ __typename?: 'PersonContribution', Contribution: { __typename?: 'Contribution', id: number, title: string, subtitle?: string | null, contributionType?: string | null, tags?: string | null, Issue: { __typename?: 'Issue', id: number, title: string, volume: string, issueNumber: string, year: string, Files: Array<{ __typename?: 'EntityFile', File?: { __typename?: 'File', url: string } | null }> } } }>, Files: Array<{ __typename?: 'EntityFile', File?: { __typename?: 'File', url: string } | null }> }> };

export type AllContributionsSlimQueryVariables = Exact<{ [key: string]: never; }>;


export type AllContributionsSlimQuery = { __typename?: 'query_root', Contribution: Array<{ __typename?: 'Contribution', id: number, title: string, subtitle?: string | null, contributionType?: string | null, runghLink?: string | null, tags?: string | null, snippet?: string | null, Files: Array<{ __typename?: 'EntityFile', File?: { __typename?: 'File', url: string } | null }>, Issue: { __typename?: 'Issue', id: number, title: string, volume: string, year: string, issueNumber: string, Files: Array<{ __typename?: 'EntityFile', File?: { __typename?: 'File', url: string } | null }> }, PersonContributions: Array<{ __typename?: 'PersonContribution', Person: { __typename?: 'Person', id: number, name: string, Files: Array<{ __typename?: 'EntityFile', File?: { __typename?: 'File', url: string } | null }> } }> }> };

export type AllIssuesSlimQueryVariables = Exact<{ [key: string]: never; }>;


export type AllIssuesSlimQuery = { __typename?: 'query_root', Issue: Array<{ __typename?: 'Issue', id: number, title: string, volume: string, issueNumber: string, year: string, runghLink?: string | null, Files: Array<{ __typename?: 'EntityFile', File?: { __typename?: 'File', url: string } | null }>, Contributions: Array<{ __typename?: 'Contribution', id: number, title: string, subtitle?: string | null, tags?: string | null, PersonContributions: Array<{ __typename?: 'PersonContribution', Person: { __typename?: 'Person', id: number, name: string, Files: Array<{ __typename?: 'EntityFile', File?: { __typename?: 'File', url: string } | null }> } }> }> }> };


export const AllArtistsSlimDocument = gql`
    query AllArtistsSlim {
  Person(order_by: {name: asc}) {
    id
    name
    runghLink
    PersonContributions {
      Contribution {
        id
        title
        subtitle
        contributionType
        tags
        Issue {
          id
          title
          volume
          issueNumber
          year
          Files {
            File {
              url
            }
          }
        }
      }
    }
    Files {
      File {
        url
      }
    }
  }
}
    `;

/**
 * __useAllArtistsSlimQuery__
 *
 * To run a query within a React component, call `useAllArtistsSlimQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllArtistsSlimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllArtistsSlimQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllArtistsSlimQuery(baseOptions?: Apollo.QueryHookOptions<AllArtistsSlimQuery, AllArtistsSlimQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllArtistsSlimQuery, AllArtistsSlimQueryVariables>(AllArtistsSlimDocument, options);
      }
export function useAllArtistsSlimLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllArtistsSlimQuery, AllArtistsSlimQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllArtistsSlimQuery, AllArtistsSlimQueryVariables>(AllArtistsSlimDocument, options);
        }
export type AllArtistsSlimQueryHookResult = ReturnType<typeof useAllArtistsSlimQuery>;
export type AllArtistsSlimLazyQueryHookResult = ReturnType<typeof useAllArtistsSlimLazyQuery>;
export type AllArtistsSlimQueryResult = Apollo.QueryResult<AllArtistsSlimQuery, AllArtistsSlimQueryVariables>;
export const AllContributionsSlimDocument = gql`
    query AllContributionsSlim {
  Contribution {
    id
    title
    subtitle
    contributionType
    runghLink
    tags
    snippet
    Files {
      File {
        url
      }
    }
    Issue {
      id
      title
      volume
      year
      issueNumber
      Files {
        File {
          url
        }
      }
    }
    PersonContributions {
      Person {
        id
        name
        Files {
          File {
            url
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAllContributionsSlimQuery__
 *
 * To run a query within a React component, call `useAllContributionsSlimQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllContributionsSlimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllContributionsSlimQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllContributionsSlimQuery(baseOptions?: Apollo.QueryHookOptions<AllContributionsSlimQuery, AllContributionsSlimQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllContributionsSlimQuery, AllContributionsSlimQueryVariables>(AllContributionsSlimDocument, options);
      }
export function useAllContributionsSlimLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllContributionsSlimQuery, AllContributionsSlimQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllContributionsSlimQuery, AllContributionsSlimQueryVariables>(AllContributionsSlimDocument, options);
        }
export type AllContributionsSlimQueryHookResult = ReturnType<typeof useAllContributionsSlimQuery>;
export type AllContributionsSlimLazyQueryHookResult = ReturnType<typeof useAllContributionsSlimLazyQuery>;
export type AllContributionsSlimQueryResult = Apollo.QueryResult<AllContributionsSlimQuery, AllContributionsSlimQueryVariables>;
export const AllIssuesSlimDocument = gql`
    query AllIssuesSlim {
  Issue(order_by: {volume: asc, issueNumber: asc}) {
    id
    title
    volume
    issueNumber
    year
    runghLink
    Files {
      File {
        url
      }
    }
    Contributions {
      id
      title
      subtitle
      tags
      PersonContributions {
        Person {
          id
          name
          Files {
            File {
              url
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAllIssuesSlimQuery__
 *
 * To run a query within a React component, call `useAllIssuesSlimQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllIssuesSlimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllIssuesSlimQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllIssuesSlimQuery(baseOptions?: Apollo.QueryHookOptions<AllIssuesSlimQuery, AllIssuesSlimQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllIssuesSlimQuery, AllIssuesSlimQueryVariables>(AllIssuesSlimDocument, options);
      }
export function useAllIssuesSlimLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllIssuesSlimQuery, AllIssuesSlimQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllIssuesSlimQuery, AllIssuesSlimQueryVariables>(AllIssuesSlimDocument, options);
        }
export type AllIssuesSlimQueryHookResult = ReturnType<typeof useAllIssuesSlimQuery>;
export type AllIssuesSlimLazyQueryHookResult = ReturnType<typeof useAllIssuesSlimLazyQuery>;
export type AllIssuesSlimQueryResult = Apollo.QueryResult<AllIssuesSlimQuery, AllIssuesSlimQueryVariables>;