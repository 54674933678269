/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Auth0Provider } from "@auth0/auth0-react"
import React from "react"
import ReactDOM from "react-dom"

import App from "./components/App/App"

import "./index.css"

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN!}
    clientId={process.env.REACT_APP_CLIENT_ID!}
    redirectUri={process.env.REACT_APP_REDIRECT_URI}
    audience={"HasuraAPI"}
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
