import React from "react"

import LeadParagraph from "./LeadParagraph"
import ModalTitle from "./ModalTitle"
import Paragraph from "./Paragraph"

const Page0 = (): JSX.Element => {
  return (
    <>
      <ModalTitle>Rungh Redux</ModalTitle>
      <div
        className="text-
      -rr-chocolate-base border-2 border-rr-cherry-dark p-2 bg-rr-orange-light"
      >
        <Paragraph>
          Rungh Redux is currently in an early release. In this preview version,
          we might still be squashing a few bugs. If you notice any, please
          email us at{" "}
          <strong>
            <a
              href="mailto:info@rungh.org?subject=Rungh Redux early release feedback"
              tabIndex={-1}
              className="underline text-rr-cherry-dark"
            >
              info@rungh.org
            </a>
          </strong>
          . Thanks!
        </Paragraph>
      </div>
      <LeadParagraph>
        Welcome to Rungh Redux. Rungh Redux is a digital network and
        discoverability tool. It invites you to explore the archive of printed
        Rungh Magazines from 1992-1999 (Volumes 1-4).
      </LeadParagraph>
      <Paragraph>
        Connect artists, to articles and art. Draw linkages between thematic
        issues and streams.
      </Paragraph>
      <Paragraph>
        Rungh Redux is also a bridge between the 1990s and the newly launched
        (2017) Rungh platform. Travel from past to present and back again. Share
        your journey via social media links and more.
      </Paragraph>
    </>
  )
}

export default Page0
