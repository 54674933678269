import { ArtistType } from "../types/ArtistType"
import { ContributionType } from "../types/ContributionType"
import { IssueType } from "../types/IssueType"
import QueryTagsType from "../types/QueryTagsType"

export const artistMatchesFilter = (
  queryTags: QueryTagsType,
  artist: ArtistType
): boolean => {
  if (!queryTags) return true

  const contributionTags = artist?.PersonContributions.map(
    (pc) => pc.Contribution.tags?.split(",").map((t: string) => t.trim()) || []
  ).flat()

  return contributionTags.some((t: string) => queryTags.includes(t))
}

export const contributionMatchesFilter = (
  queryTags: QueryTagsType,
  contribution: ContributionType
): boolean => {
  // If no queryTags, return true
  if (!queryTags) return true

  const contributionTags =
    contribution?.tags?.split(",").map((t: string) => t.trim()) || []

  return contributionTags.some((t: string) => queryTags.includes(t))
}

export const issueMatchesFilter = (
  queryTags: QueryTagsType,
  issue: IssueType
): boolean => {
  // If no queryTags, return true
  if (!queryTags) return true

  const contributionTags = issue?.Contributions.map(
    (c) => c?.tags?.split(",").map((t: string) => t.trim()) || []
  ).flat()

  return contributionTags.some((t: string) => queryTags.includes(t))
}
