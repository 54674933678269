import React from "react"

interface HeaderProps {
  children: React.ReactNode
}

const Header = ({ children }: HeaderProps): JSX.Element => {
  return (
    <h2 className="font-bold text-base sm:text-xl text-center h-[calc(1.5rem)] sm:h-[calc(3rem)] sm:py-2 py-0">
      {children}
    </h2>
  )
}

export default Header
