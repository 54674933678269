import { ArtistType } from "../types/ArtistType"
import { ContributionType } from "../types/ContributionType"
import { IssueType } from "../types/IssueType"
import SearchTextType from "../types/SearchTextType"

const searchToLowercase = (searchText: SearchTextType): SearchTextType => {
  return searchText?.toLowerCase()
}

export const artistMatchesSearch = (
  searchText: SearchTextType,
  artist: ArtistType
): boolean => {
  const searchTextLowercase = searchToLowercase(searchText)

  // If no search text, just return true
  if (!searchTextLowercase) return true

  const { name } = artist

  const textToSearch = name.toLowerCase()

  return textToSearch.includes(searchTextLowercase)
}

export const contributionMatchesSearch = (
  searchText: SearchTextType,
  contribution: ContributionType
): boolean => {
  const searchTextLowercase = searchToLowercase(searchText)

  // If no search text, just return true
  if (!searchTextLowercase) return true

  const { title, subtitle, snippet } = contribution
  const authorNames = contribution.PersonContributions.map(
    (p) => p.Person.name
  ).join(" ")

  const textToSearch = [title, subtitle, snippet, authorNames]
    .join(" ")
    .toLowerCase()

  return textToSearch.includes(searchTextLowercase)
}

export const issueMatchesSearch = (
  searchText: SearchTextType,
  issue: IssueType
): boolean => {
  const searchTextLowercase = searchToLowercase(searchText)

  // If no search text, just return true
  if (!searchTextLowercase) return true

  const { title } = issue

  console.log("title", title)

  const textToSearch = title.toLowerCase()

  return textToSearch.includes(searchTextLowercase)
}
