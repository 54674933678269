import React from "react"

interface LeadParagraphProps {
  children: React.ReactNode
}

const LeadParagraph = ({ children }: LeadParagraphProps): JSX.Element => {
  return (
    <p className="text-sm sm:text-lg font-bold mb-2 leading-snug">{children}</p>
  )
}

export default LeadParagraph
