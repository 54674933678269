import React from "react"

import { Dialog } from "@headlessui/react"

interface ModalTitleProps {
  children: React.ReactNode
}

const ModalTitle = ({ children }: ModalTitleProps): JSX.Element => {
  return (
    <Dialog.Title
      as="h3"
      className="my-1 sm:my-3 text-lg sm:text-2xl leading-6 font-bold text-rr-chocolate text-center"
    >
      {children}
    </Dialog.Title>
  )
}

export default ModalTitle
