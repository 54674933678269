import React from "react"
import Image from "./Image"

import LeadParagraph from "./LeadParagraph"
import ModalTitle from "./ModalTitle"
import Paragraph from "./Paragraph"

import shot4 from "./shot4.gif"

const Page4 = (): JSX.Element => {
  return (
    <>
      <ModalTitle>Exploring Nodes</ModalTitle>
      <LeadParagraph>
        Nodes are initially in a collapsed state to make them easy to browse.
      </LeadParagraph>
      <Image src={shot4} alt="A collapsed node, showing only the title." />
      <Paragraph>
        To view more detail on an Issue, Contribution, or Artist, click or tap
        the node to expand it. Click on the links within an expanded node to
        explore connected issues, contributions, and artists.
      </Paragraph>
    </>
  )
}

export default Page4
