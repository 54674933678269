/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { ShareIcon } from "@heroicons/react/solid"

const shares = [
  {
    name: "Facebook",
    href: "https://www.facebook.com/sharer/sharer.php?u=%%%url%%%&title=%%%title%%%",
    icon: "fa-brands fa-facebook",
  },
  {
    name: "Twitter",
    href: "https://twitter.com/intent/tweet?text=%%%title%%%%20%%%url%%%",
    icon: "fa-brands fa-twitter",
  },
  {
    name: "Email",
    href: "mailto:?to=&body=%%%url%%%&subject=%%%title%%%",
    icon: "fas fa-envelope",
  },
]

interface SharePopoverProps {
  slug: string
  title: string
}

export default function SharePopover({ title, slug }: SharePopoverProps) {
  const url = `https://redux.rungh.org/%23/${slug}`

  return (
    <Popover className="relative ml-1">
      <Popover.Button
        className={`border bg-rr-khaki-darkest hover:bg-rr-khaki-dark border-rr-khaki-darkest text-white hover:text-slate-800 rounded p-1 text-xxs flex items-center ml-auto`}
      >
        <ShareIcon className="w-4 h-4" />
        <span className="ml-1 hidden lg:block">Share</span>
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-2/3 px-2 w-30 md:w-36 sm:px-0">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="relative grid gap-2 md:gap-6 bg-rr-khaki-light p-2 md:p-3">
              {shares.map((item) => (
                <a
                  key={item.name}
                  href={item.href
                    .replace("%%%title%%%", title)
                    .replace("%%%url%%%", url)}
                  className="-m-2 p-2 flex items-center rounded-lg hover:bg-rr-khaki-base transition ease-in-out duration-150"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className={`${item.icon} pt-0.5 leading-tight`} />
                  <div className="ml-2">
                    <p className="text-xs md:text-base font-medium text-gray-900 leading-tight">
                      {item.name}
                    </p>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
