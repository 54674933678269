import React from "react"

import TagList from "./TagList"

export const ALL_TAGS: { Type: string[]; Content: string[] } = {
  Type: [
    "Conversation",
    "Dance",
    "Fiction",
    "Film",
    "Literature",
    "Music",
    "Poetry",
    "Reflection",
    "Review",
  ],
  Content: [
    "Archive",
    "Artist Run Centre",
    "Body",
    "Desh Pradesh",
    "Queer",
    "Roots",
  ],
}

const Tags = (): JSX.Element => {
  return (
    <div className="Tags row border-dark border-bottom py-2 py-md-3">
      <div className="col-12">
        <div className="d-flex align-items-center w-100 justify-content-md-center justify-content-start">
          <div>
            <TagList tags={ALL_TAGS.Type} />
            <div className="my-2"></div>
            <TagList tags={ALL_TAGS.Content} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tags
