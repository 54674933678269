import { ArtistType } from "../types/ArtistType"
import {
  artistMatchesFilter,
  contributionMatchesFilter,
  issueMatchesFilter,
} from "./FilterHelper"
import {
  artistMatchesSearch,
  contributionMatchesSearch,
  issueMatchesSearch,
} from "./SearchHelper"
import { artistSort, contributionSort, issueSort } from "./SortHelper"
import { ContributionType } from "../types/ContributionType"
import QueryTagsType from "../types/QueryTagsType"
import SearchTextType from "../types/SearchTextType"
import { IssueType } from "../types/IssueType"

export const processArtistData = (
  data: ArtistType[],
  queryTags: QueryTagsType,
  searchText: SearchTextType
): ArtistType[] => {
  return data
    .map((a: ArtistType) => {
      const filterMatch = artistMatchesFilter(queryTags, a)
      const searchMatch = artistMatchesSearch(searchText, a)
      a.inScope = filterMatch && searchMatch
      return a
    })
    .sort(artistSort)
}

export const processContributionData = (
  data: ContributionType[],
  queryTags: QueryTagsType,
  searchText: SearchTextType
): ContributionType[] => {
  return data
    .map((c: ContributionType) => {
      const filterMatch = contributionMatchesFilter(queryTags, c)
      const searchMatch = contributionMatchesSearch(searchText, c)
      c.inScope = filterMatch && searchMatch
      return c
    })
    .sort(contributionSort)
}

export const processIssueData = (
  data: IssueType[],
  queryTags: QueryTagsType,
  searchText: SearchTextType
): IssueType[] => {
  return data
    .map((i: IssueType) => {
      const filterMatch = issueMatchesFilter(queryTags, i)
      const searchMatch = issueMatchesSearch(searchText, i)
      i.inScope = filterMatch && searchMatch
      return i
    })
    .sort(issueSort)
}
