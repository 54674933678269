import React, { useEffect, useState } from "react"
import { ArrayParam, StringParam, useQueryParam } from "use-query-params"

import { IssueType } from "../../types/IssueType"
import { processIssueData } from "../../helpers/DataHelper"
import { useAllIssuesSlimQuery } from "../../generated/graphql"
import IssueListItem from "./IssueListItem"
import ListWrapper from "../Elements/ListWrapper"
import HeaderLink from "../Elements/HeaderLink"

interface IssueListProps {
  setLastLoadTs: (ts: number) => void
}

const IssueList = ({ setLastLoadTs }: IssueListProps): JSX.Element => {
  const [sortedData, setSortedData] = useState<IssueType[]>([])

  const { loading, data, error } = useAllIssuesSlimQuery()

  const [queryTags] = useQueryParam("tags", ArrayParam)
  const [querySearch] = useQueryParam("search", StringParam)

  useEffect(() => {
    if (data) {
      setLastLoadTs(Date.now())
      setSortedData(processIssueData(data.Issue, queryTags, querySearch))
    }
  }, [data, setLastLoadTs, queryTags, querySearch])

  if (loading || error || !data) {
    return <div className="w-1/3">Loading...</div>
  }

  return (
    <ListWrapper
      title="Issues"
      id="IssueList"
      next={
        <HeaderLink linkId="contribution" title="Contributions" arrow="right" />
      }
    >
      {sortedData.map((i) => (
        <IssueListItem issue={i} key={i.id} />
      ))}
    </ListWrapper>
  )
}

export default IssueList
