import React from "react"

interface SmallTagProps {
  tagName: string
}

// const COLOR_MAP: Record<string, string> = {
//   Conversation: "bg-rr-forest-dark",
//   Dance: "bg-rr-chocolate-dark",
//   Fiction: "bg-rr-ochre-dark",
//   Film: "bg-rr-navy-dark",
//   Literature: "bg-rr-cerulean-dark",
//   Music: "bg-rr-lemon-dark",
//   Poetry: "bg-rr-plum-dark",
//   Reflection: "bg-rr-lavender-dark",
//   Review: "bg-rr-cashmere-dark",
//   Archive: "bg-rr-caramel-dark",
//   "Artist Run Centre": "bg-rr-orange-dark",
//   Body: "bg-rr-cherry-dark",
//   "Desh Pradesh": "bg-rr-grass-dark",
//   Queer: "bg-rr-chocolate-dark",
//   Roots: "bg-rr-ochre-dark",
// }

const SmallTag = ({ tagName }: SmallTagProps): JSX.Element => {
  return (
    <div
      className={`mr-1 sm:mr-2 text-xs sm:text-sm inline-block rounded py-0 sm:py-1 px-1 sm:px-2 border border-rr-chocolate-dark text-rr-chocolate-dark`}
    >
      {tagName}
    </div>
  )
}

export default SmallTag
