import slugify from "slugify"
import IntentionalAny from "../types/IntentionalAny"

const ID_SEPARATOR = "-"
const URL_SEPARATOR = "/"

export const buildSlug = (
  obj: IntentionalAny,
  options: { url?: boolean; useId?: boolean } = {}
): string => {
  if (!obj) {
    console.error("buildSlug: No obj!")
  }
  if (!obj.name && !obj.title) {
    console.error("buildSlug: Neither a name nor a title was passed!")
  }
  return `${obj.__typename.toLowerCase()}${
    options.url ? URL_SEPARATOR : ID_SEPARATOR
  }${slugify(
    options.useId && obj.id ? `${obj.id}` : obj.name || obj.title || "",
    { lower: true, remove: /[^\w -]/g }
  )}`
}

export const isActiveList = (targetType: string, type?: string): boolean => {
  return targetType === type
}

export const isActiveId = (
  targetTypeId: string,
  type?: string,
  id?: string
): boolean => {
  const split = targetTypeId.split(ID_SEPARATOR)
  const targetType = split.shift() // Get the first item
  const targetId = split.join(ID_SEPARATOR) // Join the rest again

  return (
    targetType !== undefined &&
    targetId !== undefined &&
    targetType === type &&
    targetId === id
  )
}

export const classIfActive = (isActive: boolean): string => {
  return isActive ? "Active" : ""
}
