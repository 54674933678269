import { ArtistType } from "../types/ArtistType"
import { ContributionType } from "../types/ContributionType"
import { IssueType } from "../types/IssueType"

export const contributionSort = (a: ContributionType, b: ContributionType) => {
  const inScope = Number(b.inScope) - Number(a.inScope)

  const volumeA = isNaN(Number(a.Issue.volume)) ? 0 : Number(a.Issue.volume)
  const volumeB = isNaN(Number(b.Issue.volume)) ? 0 : Number(b.Issue.volume)

  const issueA = isNaN(Number(a.Issue.issueNumber))
    ? 0
    : Number(a.Issue.issueNumber)
  const issueB = isNaN(Number(b.Issue.issueNumber))
    ? 0
    : Number(b.Issue.issueNumber)

  const snippetA = a.snippet || "Z" // Move items without snippet to the end
  const snippetB = b.snippet || "Z"

  return (
    inScope ||
    volumeA - volumeB ||
    issueA - issueB ||
    snippetA.localeCompare(snippetB)
  )
}

export const artistSort = (a: ArtistType, b: ArtistType) => {
  return Number(b.inScope) - Number(a.inScope)
}

export const issueSort = (a: IssueType, b: IssueType) => {
  return Number(b.inScope) - Number(a.inScope)
}
