import React from "react"

import { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"

import Tags from "../Slideover/Tags"
import { ArrayParam, useQueryParam } from "use-query-params"
import { XIcon } from "@heroicons/react/outline"
import Search from "./Search"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

export default function Flyout() {
  const [queryTags] = useQueryParam("tags", ArrayParam)

  return (
    <Popover className="z-40 top-[calc(2.5rem)] sm:top-[calc(4rem)] w-full fixed">
      {({ open }) => (
        <>
          <div className="relative z-40 bg-gray-800 shadow h-[calc(2.5rem)] sm:h-[calc(4rem)] p-2 sm:p-3 flex items-center justify-between">
            <div className="grow flex items-center">
              <Popover.Button
                className={classNames(
                  "border border-white sm:text-sm py-1 sm:py-4 px-1 sm:px-3 group bg-gray-800 rounded inline-flex items-center  font-medium  focus:outline-none text-white text-xs h-6 hover:bg-gray-700"
                )}
              >
                <span>Filter</span>
                <ChevronDownIcon
                  className={classNames(
                    open ? "text-gray-600" : "text-gray-400",
                    "ml-0 sm:ml-2 h-5 w-5 group-hover:text-gray-500"
                  )}
                  aria-hidden="true"
                />
              </Popover.Button>
              {queryTags && queryTags.length ? (
                <>
                  <div className="hidden sm:flex items-center">
                    <div className="text-gray-300 ml-1 sm:ml-4 text-xs sm:text-sm">
                      <span className="hidden sm:inline">Active filters:</span>
                    </div>
                    {queryTags.map((tag) => (
                      <div
                        className="text-xs text-white ml-1 sm:ml-2 rounded py-1 sm:px-2 border border-rr-khaki-dark bg-rr-chocolate-base px-1"
                        key={tag}
                      >
                        {tag}
                      </div>
                    ))}
                  </div>
                  <div className="ml-2 sm:hidden text-xs text-white rounded py-1 sm:px-2 border border-rr-khaki-dark bg-rr-chocolate-base px-1">
                    {queryTags.length} filters
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <Search />
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel className="absolute z-50 inset-x-0 transform shadow-lg shadow-rr-khaki-dark w-full md:w-128">
              <div className="absolute inset-0 flex" aria-hidden="true">
                <div className="bg-rr-khaki-light w-1/2" />
                <div className="bg-rr-khaki-light w-1/2" />
              </div>
              <div className="relative w-full mx-auto">
                <nav
                  className="gap-y-2 gap-x-2 sm:gap-y-10 p-2 bg-rr-khaki-light sm:grid-cols-4 sm:gap-x-8 sm:py-12 sm:px-6 lg:px-8 xl:pr-12 w-full text-sm sm:text-base flex flex-col sm:flex-row"
                  aria-labelledby="solutions-heading"
                >
                  <h2 id="solutions-heading" className="sr-only">
                    Filter menu
                  </h2>
                  <div>
                    <h3 className="font-bold text-base sm:text-xl mb-2">
                      Filters
                    </h3>
                    <p className="sm:text-base leading-snug text-sm">
                      Filters allow you to browse the history of Rungh Redux
                      thematically. Click a filter to show only contributions
                      matching that topic.
                    </p>
                    <Tags />
                  </div>
                </nav>
                <div className="w-full relative bottom-3 right-3 sm:bottom-4 sm:right-4 md:bottom-5 md:right-5">
                  <Popover.Button
                    className={`ml-auto rounded border border-rr-chocolate-dark py-1 px-2 text-white bg-rr-chocolate-dark hover:bg-chocolate-base text-xs sm:text-sm flex align-center pr-3 items-center`}
                  >
                    <XIcon
                      className={`mr-2 h-4 w-4 sm:h-5 sm:w-5`}
                      aria-hidden="true"
                    />
                    <span>Close</span>
                  </Popover.Button>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
