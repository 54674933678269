import { StringParam, useQueryParam } from "use-query-params"
import React, { useCallback, useEffect, useState } from "react"
import { XIcon } from "@heroicons/react/solid"

const Search = (): JSX.Element => {
  const [searchQuery, setSearchQuery] = useQueryParam("search", StringParam)
  const [searchInputText, setSearchInputText] = useState<string>("")

  const border =
    "border border-white sm:text-sm py-1 sm:py-4 px-1 sm:px-3 group  rounded inline-flex items-center  font-medium  text-white text-xs h-6 bg-gray-800"

  const searchInputCallback = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchText = event.target.value
      setSearchInputText(searchText)
    },
    []
  )

  useEffect(() => {
    setSearchQuery(searchInputText.length ? searchInputText : undefined)
  }, [searchInputText, setSearchQuery])

  const showClearButton = searchQuery && searchQuery.length

  const textInputRightSide = showClearButton
    ? "border-r-0 rounded-tr-none rounded-br-none"
    : ""

  const clearButton = (
    <button
      className={`${border} rounded-tl-none rounded-bl-none flex items-center hover:bg-gray-700`}
      onClick={() => setSearchInputText("")}
    >
      <XIcon className="h-4 w-4" />
      <div className="ml-1">Clear</div>
    </button>
  )

  return (
    <div className="flex items-stretch">
      <input
        type="text"
        placeholder="Type to search..."
        className={`${border} ${textInputRightSide} focus:bg-gray-700 focus:outline-gray-500 focus:ring ${
          showClearButton ? "w-24 sm:w-48" : "w-32 sm:w-56"
        }`}
        onChange={searchInputCallback}
        value={searchInputText}
      />
      {showClearButton && clearButton}
    </div>
  )
}

export default Search
