import React from "react"

interface RoundedImageProps {
  url?: string
  size?: "xl" | "large" | "medium" | "small"
  className?: string
}

import imageNotProvided from "./bio-image-not-provided.jpg"

const RoundedImage = ({
  url,
  size = "medium",
  className,
}: RoundedImageProps): JSX.Element => {
  const sizeClass =
    size === "xl"
      ? "w-36 h-36"
      : size === "large"
      ? "w-16 h-16 md:w-18 md:h-18 lg:w-24 lg:h-24"
      : size === "medium"
      ? "w-12 h-12 md:w-16 md:h-16 lg:w-18 lg:h-18"
      : "w-8 h-8"
  if (url) {
    const newUrl = url.replaceAll("aws.com", "aws.com/300")
    console.log(newUrl)

    const finalUrl = newUrl.split(".").slice(0, -1).join(".").concat(".jpg")

    console.log(finalUrl)

    return (
      <img
        className={`shrink-0 overflow-clip border border-slate-900 rounded-full object-cover ${sizeClass} ${className}`}
        alt=""
        src={finalUrl}
      />
    )
  }
  return (
    // <div
    //   className={`border border-slate-900 rounded-full bg-slate-500 ${sizeClass} ${className}`}
    // />
    <img
      className={`overflow-clip border border-slate-900 rounded-full object-cover ${sizeClass} ${className}`}
      alt="Image not provided"
      src={imageNotProvided}
    />
  )
}

export default RoundedImage
