import React from "react"

interface Props {
  currentPage: number
  setCurrentPage: (num: number) => void
  numPages: number
  setOpen: (open: boolean) => void
}

const WelcomePagination = ({
  currentPage,
  setCurrentPage,
  numPages,
  setOpen,
}: Props): JSX.Element => {
  const buttonClasses =
    "rounded border border-rr-chocolate-dark py-1 px-2 hover:text-white hover:bg-rr-chocolate-dark text-xs sm:text-sm"

  return (
    <div className="flex justify-between mt-4">
      <div className="w-1/3 basis-full">
        {currentPage > 0 && (
          <button
            className={`mr-auto ${buttonClasses}`}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            &larr; Previous <span className="hidden sm:inline">page</span>
          </button>
        )}
      </div>
      <div className="w-1/3 text-center basis-full">
        <button
          type="button"
          className={`rounded border border-rr-chocolate-dark py-1 px-2 text-white bg-rr-chocolate-dark hover:bg-chocolate-base text-xs sm:text-sm`}
          onClick={() => setOpen(false)}
        >
          Close
        </button>
      </div>
      <div className="w-1/3 basis-full text-right">
        {currentPage + 1 < numPages && (
          <button
            className={`ml-auto ${buttonClasses}`}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Next <span className="hidden sm:inline">page</span> &rarr;
          </button>
        )}
      </div>
    </div>
  )
}

export default WelcomePagination
