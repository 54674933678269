import React from "react"

interface ListItemTitleProps extends React.HTMLProps<HTMLHeadingElement> {
  children: React.ReactNode
}

const ListItemTitle = (props: ListItemTitleProps): JSX.Element => {
  return (
    <h3
      {...props}
      className={`tracking-tight leading-tight sm:leading-5 ${props.className}`}
    />
  )
}

export default ListItemTitle
