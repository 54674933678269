import React from "react"

import LeadParagraph from "./LeadParagraph"
import ModalTitle from "./ModalTitle"
import Paragraph from "./Paragraph"

import palette from "./palette.png"
import Image from "./Image"

const Page5 = (): JSX.Element => {
  return (
    <>
      <ModalTitle>A Historical Palette</ModalTitle>

      <div className="flex  sm:flex-row">
        <Image
          src={palette}
          alt="The palette of colours as they map to the magazine issues."
        />
        <div className="pl-3">
          <LeadParagraph>
            Issue and Contribution nodes in Rungh Redux are colour-coded.
          </LeadParagraph>
          <Paragraph>
            Issue colours are drawn from the original printed covers of Rungh
            magazine. Contributions are coloured according to the Issue in which
            they appeared.
          </Paragraph>
        </div>
      </div>
    </>
  )
}

export default Page5
