import React, { useEffect, useState } from "react"
import { ArrayParam, StringParam, useQueryParam } from "use-query-params"
import { useAllContributionsSlimQuery } from "../../generated/graphql"
import { processContributionData } from "../../helpers/DataHelper"
import { ContributionType } from "../../types/ContributionType"
import HeaderLink from "../Elements/HeaderLink"
import ListWrapper from "../Elements/ListWrapper"
import ContributionListItem from "./ContributionListItem"

interface ContributionListProps {
  setLastLoadTs: (ts: number) => void
}

const ContributionList = ({
  setLastLoadTs,
}: ContributionListProps): JSX.Element => {
  const [sortedData, setSortedData] = useState<ContributionType[]>([])
  const { loading, data, error } = useAllContributionsSlimQuery()

  const [queryTags] = useQueryParam("tags", ArrayParam)
  const [querySearch] = useQueryParam("search", StringParam)

  useEffect(() => {
    if (data) {
      setLastLoadTs(Date.now())
      setSortedData(
        processContributionData(data.Contribution, queryTags, querySearch)
      )
    }
  }, [data, setLastLoadTs, queryTags, querySearch])

  if (loading || error || !data) {
    return <div className="w-1/3">Loading...</div>
  }

  return (
    <ListWrapper
      title="Contributions"
      id="ContributionList"
      prev={<HeaderLink linkId="issue" title="Issues" arrow="left" />}
      next={<HeaderLink linkId="person" title="Artists" arrow="right" />}
    >
      {sortedData.map((c) => (
        <ContributionListItem contribution={c} key={c.id} />
      ))}
    </ListWrapper>
  )
}

export default ContributionList
