import React from "react"

import Image from "./Image"
import LeadParagraph from "./LeadParagraph"
import ModalTitle from "./ModalTitle"
import Paragraph from "./Paragraph"

import shot1 from "./shot1.png"

const Page2 = (): JSX.Element => {
  return (
    <>
      <ModalTitle>Explore Rungh Redux</ModalTitle>
      <LeadParagraph>
        Rungh Redux organizes Issues, Contributions, and Artists into three
        vertical lists, or &ldquo;tapestries&rdquo;.
      </LeadParagraph>
      <Image src={shot1} alt="A screenshot of the application." />
      {/* <Paragraph>
        Click the <strong>Issues</strong>, <strong>Contributions</strong>, and{" "}
        <strong>Artists</strong> headers to switch the active tapestry.
      </Paragraph> */}
      <Paragraph>
        Each tapestry is individually scrollable. Scroll to explore the entries
        within each tapestry.
      </Paragraph>
    </>
  )
}

export default Page2
