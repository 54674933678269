import { Transition } from "@headlessui/react"
import { useParams } from "react-router"
import React, { useCallback, useState } from "react"

import { AllListsParams } from "../AllLists/AllLists"
import { isActiveId } from "../../helpers/Helpers"
import ListItemTitle from "./ListItemTitle"
import RoundedImage from "./RoundedImage"
import SharePopover from "../Popover/SharePopover"

type IssueColorType = {
  bg: string
  border: string
  darkBg?: string
  frieze: string
  hover: string
  maskSvg: string
}

export const ISSUE_COLOR_MAP: Record<number, IssueColorType> = {
  59: {
    hover:
      "hover:bg-issue1-light group-hover:bg-issue1-base hover:border-issue1-base group-hover:border-issue1-base",
    bg: "bg-issue1-light",
    darkBg: "bg-issue1-dark",
    frieze: "bg-issue1-base",
    border: "border-issue1-base",
    maskSvg: "linear-w-dots.svg",
  },
  1: {
    hover:
      "hover:bg-issue2-light group-hover:bg-issue2-base hover:border-issue2-base group-hover:border-issue2-base",
    bg: "bg-issue2-light",
    darkBg: "bg-issue2-dark",
    frieze: "bg-issue2-base",
    border: "border-issue2-base",
    maskSvg: "eyes.svg",
  },
  56: {
    hover:
      "hover:bg-issue3-light group-hover:bg-issue3-base hover:border-issue3-base group-hover:border-issue3-base",
    bg: "bg-issue3-light",
    darkBg: "bg-issue3-dark",
    frieze: "bg-issue3-base",
    border: "border-issue3-base",
    maskSvg: "hands.svg",
  },
  71: {
    hover:
      "hover:bg-issue3-light group-hover:bg-issue3-base hover:border-issue3-base group-hover:border-issue3-base",
    bg: "bg-issue3-light",
    darkBg: "bg-issue3-dark",
    frieze: "bg-issue3-base",
    border: "border-issue3-base",
    maskSvg: "hands.svg",
  },
  57: {
    hover:
      "hover:bg-issue4-light group-hover:bg-issue4-base hover:border-issue4-base group-hover:border-issue4-base",
    bg: "bg-issue4-light",
    darkBg: "bg-issue4-dark",
    frieze: "bg-issue4-base",
    border: "border-issue4-base",
    maskSvg: "wrought-1.svg",
  },
  58: {
    hover:
      "hover:bg-issue5-light group-hover:bg-issue5-base hover:border-issue5-base group-hover:border-issue5-base",
    bg: "bg-issue5-light",
    darkBg: "bg-issue5-dark",
    frieze: "bg-issue5-base",
    border: "border-issue5-base",
    maskSvg: "wrought-2.svg",
  },
  72: {
    hover:
      "hover:bg-issue6-light group-hover:bg-issue6-base hover:border-issue6-base group-hover:border-issue6-base",
    bg: "bg-issue6-light",
    darkBg: "bg-issue6-dark",
    frieze: "bg-issue6-base",
    border: "border-issue6-base",
    maskSvg: "flowers.svg",
  },
  60: {
    hover:
      "hover:bg-issue7-light group-hover:bg-issue7-base hover:border-issue7-base group-hover:border-issue7-base",
    bg: "bg-issue7-light",
    darkBg: "bg-issue7-dark",
    frieze: "bg-issue7-base",
    border: "border-issue7-base",
    maskSvg: "citrus-slices.svg",
  },
  73: {
    hover:
      "hover:bg-issue8-light group-hover:bg-issue8-base hover:border-issue8-base group-hover:border-issue8-base",
    bg: "bg-issue8-light",
    darkBg: "bg-issue8-dark",
    frieze: "bg-issue8-base",
    border: "border-issue8-base",
    maskSvg: "cinema-filmstrip.svg",
  },
  74: {
    hover:
      "hover:bg-issue9-light group-hover:bg-issue9-base hover:border-issue9-base group-hover:border-issue9-base",
    bg: "bg-issue9-light",
    darkBg: "bg-issue9-dark",
    frieze: "bg-issue9-base",
    border: "border-issue9-base",
    maskSvg: "wrought-3.svg",
  },
  75: {
    hover:
      "hover:bg-issue10-light group-hover:bg-issue10-base hover:border-issue10-base group-hover:border-issue10-base",
    bg: "bg-issue10-light",
    darkBg: "bg-issue10-dark",
    frieze: "bg-issue10-base",
    border: "border-issue10-base",
    maskSvg: "circle-of-as.svg",
  },
  76: {
    hover:
      "hover:bg-issue11-light group-hover:bg-issue11-base hover:border-issue11-base group-hover:border-issue11-base",
    bg: "bg-issue11-light",
    darkBg: "bg-issue11-dark",
    frieze: "bg-issue11-base",
    border: "border-issue11-base",
    maskSvg: "hands-2.svg",
  },
  77: {
    hover:
      "hover:bg-issue12-light group-hover:bg-issue12-base hover:border-issue12-base group-hover:border-issue12-base",
    bg: "bg-issue12-light",
    darkBg: "bg-issue12-dark",
    frieze: "bg-issue12-base",
    border: "border-issue12-base",
    maskSvg: "symbol.svg",
  },
  78: {
    hover:
      "hover:bg-issue13-light group-hover:bg-issue13-base hover:border-issue13-base group-hover:border-issue13-base",
    bg: "bg-issue13-light",
    darkBg: "bg-issue13-dark",
    frieze: "bg-issue13-base",
    border: "border-issue13-base",
    maskSvg: "head.svg",
  },
}

interface ListItemProps {
  children?: React.ReactNode
  compact?: boolean
  hideFrieze?: boolean
  idSlug: string
  imgUrl?: string
  isExtraLong?: boolean
  issueId?: number
  shareTitle: string
  title: React.ReactNode
  urlSlug: string
}

const ListItem = ({
  compact,
  children,
  imgUrl,
  title,
  shareTitle,
  idSlug,
  issueId,
  isExtraLong,
  hideFrieze,
  urlSlug,
}: ListItemProps): JSX.Element => {
  const [active, setActive] = useState<boolean>(false)

  const { type, id } = useParams<AllListsParams>()

  const highlighted = isActiveId(idSlug, type, id)

  const {
    bg: bgColor,
    border: borderColor,
    frieze: friezeBgColor,
    maskSvg,
    hover,
  } = issueId && ISSUE_COLOR_MAP[issueId]
    ? ISSUE_COLOR_MAP[issueId]
    : ({
        hover:
          "hover:bg-rr-khaki-dark group-hover:bg-rr-khaki-darkest hover:border-rr-khaki-darkest group-hover:border-rr-khaki-darkest",
        bg: "bg-rr-khaki-dark",
        darkBg: "bg-rr-khakidark",
        border: "border-rr-khaki-darkest",
        maskSvg: "small-flower.svg",
        frieze: "bg-rr-khaki-darkest",
      } as IssueColorType)

  // console.log(issueId, bgColor)

  const toggleActive = useCallback(() => {
    setActive(!active)
  }, [active])

  if (compact && !highlighted) {
    return (
      <div className={`py-0.5 text-xs px-4 sm:px-10`}>
        <div className="border border-rr-khaki-dark text-rr-khaki-dark py-0 px-2">
          {title}
        </div>
      </div>
    )
  }

  const expanded = active || highlighted === true

  return (
    <div className={`relative overflow-visible`} id={idSlug}>
      <div
        className="relative overflow-visible pl-0 pr-0 py-2 sm:py-4 transition-box duration-1000 ease-in-out cursor-pointer"
        onClick={toggleActive}
      >
        <div
          className={`group border transition-box duration-1000 ease-in-out overflow-visible rounded-tl-xl sm:rounded-tl-3xl relative ml-4 mr-2 md:mr-4 lg:mx-10 ${hover} shadow-lg shadow-rr-khaki-dark ring-1 ring-black/5  ${
            expanded
              ? `items-top ${bgColor} ${borderColor}`
              : "items-center bg-rr-khaki-light border-rr-khaki-dark"
          }`}
        >
          <div className="flex gap-2 sm:gap-6 w-full">
            <RoundedImage
              url={imgUrl}
              size={expanded ? "large" : "medium"}
              className={`border-2 ${borderColor} absolute transition-image duration-1000 ease-in-out z-10 ${
                expanded
                  ? "-top-2 -left-4 lg:-left-10 shadow-lg"
                  : "top-0 -left-3 lg:-left-4 shadow-sm"
              }`}
            />
            <div
              className={`flex flex-col py-3 sm:py-5 ${
                expanded ? "pl-14" : "pl-12"
              } sm:pl-16 pr-2 sm:pr-4 lg:pr-8 w-full`}
            >
              <div className="flex lg:items-center lg:justify-between">
                <ListItemTitle
                  className={`${
                    expanded
                      ? "text-base sm:text-xl font-bold"
                      : "text-sm sm:text-lg font-medium"
                  }`}
                >
                  {title}
                </ListItemTitle>
                {expanded ? (
                  <SharePopover title={shareTitle.trim()} slug={urlSlug} />
                ) : (
                  <></>
                )}
              </div>
              <div className="ml-0 md:-ml-12 lg:ml-0">
                <Transition
                  show={expanded}
                  enter="transition-box duration-500"
                  enterFrom="opacity-0 max-h-0"
                  enterTo={`opacity-100 ${
                    isExtraLong ? "max-h-320" : "max-h-128"
                  }`}
                  leave="transition-box duration-500"
                  leaveFrom={`opacity-100 ${
                    isExtraLong ? "max-h-320" : "max-h-128"
                  }`}
                  leaveTo="opacity-0 max-h-0"
                >
                  {children}
                </Transition>
              </div>
            </div>
          </div>
          {hideFrieze ? (
            <></>
          ) : (
            <div
              className={`transition-box duration-1000 ease-in-out p-1 sm:p-2 border-t ${hover} ${
                expanded
                  ? `${friezeBgColor} ${borderColor}`
                  : `bg-rr-khaki-dark border-rr-khaki-dark`
              }`}
            >
              <div
                className="h-2 sm:h-4 bg-white"
                style={{
                  WebkitMaskImage: `url("svgs/${maskSvg}")`,
                  maskImage: `url("svgs/${maskSvg}")`,
                }}
              ></div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ListItem
