import React from "react"
import { buildSlug } from "../../helpers/Helpers"
import QSNavLink from "../Elements/QSNavLink"
import RoundedImage from "../Elements/RoundedImage"

interface ArtistMiniProps {
  artist: any
}

const ArtistMini = ({ artist }: ArtistMiniProps): JSX.Element => {
  return (
    <div className="flex mb-1">
      <QSNavLink
        path={`/${buildSlug(artist, { url: true })}`}
        className="flex items-center"
      >
        <RoundedImage
          size="small"
          url={artist?.Files?.[0]?.File?.url}
          className="mr-1 sm:mr-2"
        />
        <div className="text-sm sm:text-base">{artist.name}</div>
      </QSNavLink>
    </div>
  )
}

export default ArtistMini
