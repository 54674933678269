import React from "react"
import Image from "./Image"

import LeadParagraph from "./LeadParagraph"
import ModalTitle from "./ModalTitle"
import Paragraph from "./Paragraph"

import shot2 from "./shot2.png"

const Page3 = (): JSX.Element => {
  return (
    <>
      <ModalTitle>Filtering</ModalTitle>
      <LeadParagraph>
        Use filters to explore themes in Rungh&rsquo;s history.
      </LeadParagraph>
      <Image src={shot2} alt="A screen capture of the filtering interface." />
      <Paragraph>
        Use the Explore button to choose filters. Clicking a filter toggles
        whether it&rsquo;s active or not. When filters are active, only
        contributions matching one or more of those filters will be shown
        full-size.
      </Paragraph>
    </>
  )
}

export default Page3
