import React, { useEffect } from "react"

/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"

import runghReduxLogo from "../Nav/redux_logo_white.png"
import Page0 from "./Page0"
// import Page1 from "./Page1"
import Page5 from "./Page5"
import Page2 from "./Page2"
import Page3 from "./Page3"
import Page4 from "./Page4"
import WelcomePagination from "./WelcomePagination"

const PAGES = [
  <Page0 key={"p0"} />,
  // <Page1 key={"p1"} />,
  <Page5 key={"p5"} />,
  <Page2 key={"p2"} />,
  <Page4 key={"p4"} />,
  <Page3 key={"p3"} />,
]

interface ModalProps {
  open: boolean
  setOpen: (open: boolean) => void
}

export default function Modal({ open, setOpen }: ModalProps) {
  const [currentPage, setCurrentPage] = useState<number>(0)

  const page = PAGES[currentPage]

  useEffect(() => {
    if (open) {
      // Reset to first page when opening.
      setCurrentPage(0)
    }
  }, [open])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-90 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-50 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-2 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-rr-khaki-light rounded-lg p-3 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 rounded-full">
                    <img
                      src={runghReduxLogo}
                      alt="Rungh Redux"
                      className="h-10 mx-auto invert"
                    />
                  </div>
                  <div className="mt-2 sm:mt-5">
                    <div className="">
                      {page}
                      <WelcomePagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        numPages={PAGES.length}
                        setOpen={setOpen}
                      />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
