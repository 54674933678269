import React, { useEffect, useState } from "react"
import { ArrayParam, StringParam, useQueryParam } from "use-query-params"
import { useAllArtistsSlimQuery } from "../../generated/graphql"
import { processArtistData } from "../../helpers/DataHelper"
import { ArtistType } from "../../types/ArtistType"
import HeaderLink from "../Elements/HeaderLink"
import ListWrapper from "../Elements/ListWrapper"
import ArtistListItem from "./ArtistListItem"

interface ArtistListProps {
  setLastLoadTs: (ts: number) => void
  next?: React.ReactNode
  prev?: React.ReactNode
}

const ArtistList = ({ setLastLoadTs }: ArtistListProps): JSX.Element => {
  const [sortedData, setSortedData] = useState<ArtistType[]>([])

  const { loading, data, error } = useAllArtistsSlimQuery()

  const [queryTags] = useQueryParam("tags", ArrayParam)
  const [querySearch] = useQueryParam("search", StringParam)

  useEffect(() => {
    if (data) {
      setLastLoadTs(Date.now())
      setSortedData(processArtistData(data.Person, queryTags, querySearch))
    }
  }, [data, setLastLoadTs, queryTags, querySearch])

  if (loading || error || !data) {
    return <div className="w-1/3">Loading...</div>
  }

  return (
    <ListWrapper
      title="Artists"
      id="PersonList"
      prev={
        <HeaderLink linkId="contribution" title="Contributions" arrow="left" />
      }
    >
      {sortedData.map((p) => (
        <ArtistListItem artist={p} key={p.id} />
      ))}
    </ListWrapper>
  )
}

export default ArtistList
