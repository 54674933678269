import React from "react"

import Header from "./Header"

interface ListWrapperProps {
  id: string
  children: React.ReactNode
  title: string
  prev?: React.ReactNode
  next?: React.ReactNode
}

const ListWrapper = ({
  id,
  children,
  title,
  prev,
  next,
}: ListWrapperProps): JSX.Element => {
  // const scroll = useCallback((e: any) => {
  //   console.log("e", e)
  // }, [])

  return (
    <div className="w-1/3 relative mx-0 sm:mx-2" id={id}>
      <div className="flex justify-between items-center shadow-md md:shadow-none">
        <div className="w-1/3 md:hidden">{prev}</div>
        <div className="w-1/3 md:w-full">
          <Header>{title}</Header>
        </div>
        <div className="w-1/3 md:hidden text-right">{next}</div>
      </div>
      <div
        className="ScrollToPane divide-y-1 overflow-x-visible overscroll-contain overflow-y-auto h-[calc(100vh-7rem)] sm:h-[calc(100vh-11rem)] scrollbar-thin scrollbar-thumb-rr-khaki-darkest scrollbar-track-rr-khaki-dark scrollbar-track-rounded scrollbar-thumb-rounded"
        // onScroll={scroll}
      >
        {children}
      </div>
    </div>
  )
}

export default ListWrapper
