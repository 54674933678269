/* globals $ */

import React, { useEffect, useState } from "react"
import { useParams } from "react-router"
import IntentionalAny from "../../types/IntentionalAny"
import ArtistList from "../Artists/ArtistList"
import ContributionList from "../Contributions/ContributionList"
import IssueList from "../Issues/IssueList"

import "./AllLists.css"

export interface AllListsParams {
  type?: string
  id?: string
}

const HORIZONTAL_SCROLL_DURATION_MS = 500
const VERTICAL_SCROLL_DURATION_MS = 2000

const scrollVertically = (elToScrollTo: IntentionalAny): void => {
  setTimeout(() => {
    // We set the timeout to give the previous element, if one is open, time to
    // close. If we don't do this, then when we scroll to the elToScrollTo, we
    // will be using a scrollTop measurement that does not include the fact that
    // the currently-open element is still in the process of closing, which
    // gives it too large a height.
    const listToScroll = elToScrollTo.closest(".ScrollToPane")

    if (!listToScroll) {
      console.error("No element in scrollVertically!")
      return
    }

    $(listToScroll as IntentionalAny)
      // .delay(HORIZONTAL_SCROLL_DURATION_MS - 100)
      .animate(
        {
          scrollTop: elToScrollTo.offsetTop - 50,
          queue: false,
        },
        VERTICAL_SCROLL_DURATION_MS
      )
  }, 500)
}

const scrollHorizontally = (type: string): void => {
  const listId = `${type[0].toUpperCase()}${type.substring(1)}List`
  const list = document.getElementById(listId)

  if (list) {
    const listToScrollTo = list
    const areaToScroll = listToScrollTo?.closest(".App")

    $(areaToScroll as IntentionalAny).animate(
      {
        scrollLeft: listToScrollTo?.offsetLeft,
        queue: true,
      },
      HORIZONTAL_SCROLL_DURATION_MS
    )
  }
}

const AllLists = (): JSX.Element => {
  const [lastLoadTs, setLastLoadTs] = useState<number>(0)

  const { type, id } = useParams<AllListsParams>()

  useEffect(() => {
    const elementId = `${type}-${id}`.toLocaleLowerCase()
    const elToScrollTo = document.getElementById(elementId)

    if (type) {
      scrollHorizontally(type)
    }

    if (elToScrollTo) {
      // console.log("here")
      scrollVertically(elToScrollTo)
      $(elToScrollTo as IntentionalAny).addClass("Active")
    }
  }, [type, id, lastLoadTs])

  return (
    <div className="AllLists flex w-[calc(300vw)] md:w-full h-[calc(100vh-5rem)] sm:h-[calc(100vh-8rem)] mt-20 sm:mt-32 overflow-x-hidden">
      <IssueList setLastLoadTs={setLastLoadTs} />
      <ContributionList setLastLoadTs={setLastLoadTs} />
      <ArtistList setLastLoadTs={setLastLoadTs} />
    </div>
  )
}

export default AllLists
