import React from "react"

interface ImageProps {
  src: string
  alt: string
}

const Image = ({ alt, src }: ImageProps): JSX.Element => {
  return (
    <img
      src={src}
      alt={alt}
      className={`my-3 max-h-48 sm:max-h-96 mx-auto border-2 border-rr-chocolate-dark shadow-md shadow-rr-khaki-dark`}
    />
  )
}

export default Image
