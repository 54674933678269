import React, { useMemo } from "react"
import { buildSlug } from "../../helpers/Helpers"
import { ContributionType } from "../../types/ContributionType"
import ArtistMini from "../Artists/ArtistMini"
import ListItem, { ISSUE_COLOR_MAP } from "../Elements/ListItem"
import QSNavLink from "../Elements/QSNavLink"
import SmallTag from "./SmallTag"

interface ContributionListItemProps {
  contribution: ContributionType
}

const ContributionListItem = ({
  contribution,
}: ContributionListItemProps): JSX.Element => {
  const contributionTags = useMemo(() => {
    return contribution.tags
      ? contribution.tags.split(",").map((t) => t.trim())
      : []
  }, [contribution])

  const { darkBg, bg: lightBg, border } = ISSUE_COLOR_MAP[contribution.Issue.id]

  return (
    <ListItem
      imgUrl={contribution.Issue.Files[0]?.File?.url}
      title={contribution.title}
      shareTitle={`“${contribution.title}” from Rungh magazine (${contribution.Issue.year}) on Rungh Redux`}
      compact={!contribution.inScope}
      idSlug={buildSlug(contribution)}
      urlSlug={buildSlug(contribution, { url: true })}
      issueId={contribution.Issue.id}
      isExtraLong
    >
      <div className="leading-tight text-sm sm:leading-tight sm:text-lg my-2 text-slate-700 italic pl-0 md:pl-12 lg:pl-0">
        {contribution.subtitle}
      </div>
      <QSNavLink path={`/${buildSlug(contribution.Issue, { url: true })}`}>
        <div className="text-xs text-slate-600 uppercase font-semibold pl-0 md:pl-12 lg:pl-0">
          Volume {contribution.Issue.volume} • Issue{" "}
          {contribution.Issue.issueNumber} • {contribution.Issue.year}
        </div>
      </QSNavLink>
      <div
        className="border-y border-slate-800 py-2 italic my-3 text-xs sm:text-base leading-snug cursor-text"
        onClick={(e) => e.stopPropagation()}
      >
        {contribution.snippet || "No excerpt available."}
        {contribution.runghLink ? (
          <div className="text-right mb-2 mt-4 text-xs sm:text-sm">
            <a
              className={`border hover:${lightBg} ${darkBg} ${border} text-white hover:text-slate-800 rounded py-0 sm:py-1 px-1 sm:px-2`}
              href={contribution.runghLink}
              target={"_blank"}
              rel="noreferrer"
            >
              More on Rungh &rarr;
            </a>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div>
        {contribution.PersonContributions.map((pc) => (
          <ArtistMini artist={pc.Person} key={pc.Person.id} />
        ))}
      </div>
      {contributionTags.length ? (
        <div className="mt-1 sm:mt-3 border-0 border-slate-800 pt-1 sm:pt-3">
          {contributionTags.map((tag) => (
            <SmallTag key={`${contribution.id}-${tag}`} tagName={tag} />
          ))}
        </div>
      ) : (
        <></>
      )}
    </ListItem>
  )
}

export default ContributionListItem
