import React from "react"

import { keyForTag } from "./Tag"
import TagButton from "./TagButton"

interface Props {
  tags: string[]
}

export const addTag = (tags: (string | null)[], tagName: string): void => {
  if (!hasTag(tags, tagName)) {
    tags.push(tagName)
  }
}

export const removeTag = (tags: (string | null)[], tagName: string): void => {
  if (hasTag(tags, tagName)) {
    tags.splice(tagIndex(tags, tagName), 1)
  }
}

export const tagIndex = (tags: (string | null)[], tagName: string): number => {
  return tags.indexOf(tagName)
}

export const hasTag = (
  tags: (string | null)[] | undefined | null,
  tagName: string
): boolean => {
  if (!tags) return false
  return tagIndex(tags, tagName) > -1
}

const TagList = ({ tags }: Props): JSX.Element => {
  return (
    <div className="TagList text-left d-inline d-md-block">
      {tags.map((t, i) => (
        <TagButton key={keyForTag(t, i)} tagName={t} />
      ))}
    </div>
  )
}

export default TagList
