import React from "react"

interface ParagraphProps {
  children: React.ReactNode
}

const Paragraph = ({ children }: ParagraphProps): JSX.Element => {
  return (
    <p className="mb-2 leading-tight md:leading-snug text-sm sm:text-base md:text-lg">
      {children}
    </p>
  )
}

export default Paragraph
