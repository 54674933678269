import React from "react"

interface Props {
  tagName: string
}

export const keyForTag = (tagName: string, index: number): string =>
  `${tagName}-${index}`

const Tag = ({ tagName }: Props): JSX.Element => {
  return (
    <div className={`Tag rounded ${tagName.replaceAll(" ", "-")}`}>
      {tagName}
    </div>
  )
}

export default Tag
