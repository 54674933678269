import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client"
import { HashRouter, Route } from "react-router-dom"
import { QueryParamProvider } from "use-query-params"
import { setContext } from "@apollo/client/link/context"
import { useAuth0 } from "@auth0/auth0-react"
import React, { useEffect } from "react"

import Flyout from "../Flyout/Flyout"
import Nav from "../Nav/Nav"
import Router from "../Router/Router"

export const ALL_TAGS: { Type: string[]; Content: string[] } = {
  Type: [
    "Conversation",
    "Dance",
    "Fiction",
    "Film",
    "Literature",
    "Music",
    "Poetry",
    "Reflection",
    "Review",
  ],
  Content: [
    "Archive",
    "Artist Run Centre",
    "Body",
    "Desh Pradesh",
    "Queer",
    "Roots",
  ],
}

const App: React.FunctionComponent = () => {
  const { getIdTokenClaims } = useAuth0()

  // const [activeTags, setActiveTags] = useState<string[]>([])

  useEffect(() => {
    // smoothscroll.polyfill()
  }, [])

  const httpLink = createHttpLink({ uri: process.env.REACT_APP_GRAPHQL_URI })

  const authLink = setContext(async (_, { headers }) => {
    // Get the authentication token from local storage if it exists
    const token = await getIdTokenClaims()
    // Return the headers to the context so httpLink can read them
    if (token) {
      headers = { ...headers }
      headers.authorization = `Bearer ${token.__raw}`
    }
    return {
      headers,
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })

  client.defaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  }

  return (
    <HashRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <ApolloProvider client={client}>
          <div className="App text-slate-800 bg-rr-khaki-base overflow-x-hidden">
            <Nav />
            <div className="">
              <Flyout />
            </div>
            <Router />
          </div>
        </ApolloProvider>
      </QueryParamProvider>
    </HashRouter>
  )
}

export default App
